<template>
  <div class="app-mall-service h s">
    <el-popover placement="top-end" trigger="hover" v-if="data && data.length">
      <div>
        <b class="fab fa-qq">&nbsp;QQ客服</b>
      </div>
      <div class="app-mall-service-list">
        <template v-for="o in data">
          <a class="item" :href="`tencent://message/?uin=${o.qq}&Site=${host}&Menu=yes`">
            <b>{{o.name}}</b>
            <br />
            <span>{{o.info}}</span>
          </a>
        </template>
      </div>
      <div class="service-expander fab fa-qq" slot="reference"></div>
    </el-popover>
  </div>
</template>

<script>
import initData from "@/mixins/initData";

export default {
  mixins: [initData],
  props: {
    supplier: Number | String,
  },
  data() {
    return {
      host: "",
    };
  },
  watch: {
    supplier: "toQuery",
  },
  mounted() {
    let host = window.location.host;
    if (/^localhost/gi.test(host)) host = "testback.adinghuo.com";
    this.host = host;
    this.init();
  },
  methods: {
    beforeInit() {
      if (this.supplier) {
        this.url = "api/qqService/forBuyer";
        this.params = { entId: this.supplier };
        return true;
      }
      return false;
    },
  },
};
</script>