import merge from "webpack-merge";
import baseConfig from "./config.base";

const config = {
  hosts: {
    resource: "http://localhost:8013/",
    // request: "http://my.51s.co:18881/",
    // analysis: "http://my.51s.co:18881/"
    // request: "https://testback.adinghuo.com/",
    request: "http://192.168.1.171:8881/",
    // request: "http://dtback.51s.co/"
    // request: "https://adinghuo.com:8081/",
    // request: "http://47.102.61.103:8881/"

    
    // resource: "https://adinghuo.com/",
    // request: "https://adinghuo.com:8081/",
    // analysis:"https://analysis.adinghuo.com/",
  },
};

export default merge(baseConfig, config);