import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import '@/styles/index.scss' // global css

import App from './App'
import router from './router/routers'
import permission from './components/permission'
import store from './store'

import '@/icons' // icon
import './router/index' // permission control
// import Router from 'vue-router'
import PriceInput from '@/views/components/common/priceInput'
import PercentInput from '@/views/components/common/percentInput'
import QuickSelect from '@/views/components/common/quickSelect'
import QuickCascader from '@/views/components/common/quickCascader'
import HtmlEditor from '@/views/components/common/htmlEditor'
import FileUploader from '@/views/components/common/fileUploader'
import DatagridPicker from '@/views/components/common/datagridPicker'
import TreePicker from '@/views/components/common/treePicker'
import FormInfoItem from '@/views/components/common/formInfoItem'
import RadioGroup from '@/views/components/common/radioGroup'
import Dot from '@/views/components/common/dot'
import LocalTable from '@/views/components/common/LocalTable'


import Extends from "@/utils/extends"

Vue.component("price-input", PriceInput)
Vue.component("percent-input", PercentInput)
Vue.component("quick-select", QuickSelect)
Vue.component("quick-cascader", QuickCascader)
Vue.component("html-editor", HtmlEditor)
Vue.component("file-uploader", FileUploader)
Vue.component("datagrid-picker", DatagridPicker)
Vue.component("tree-picker", TreePicker)
Vue.component("form-info-item", FormInfoItem)
Vue.component("radio-group", RadioGroup)
Vue.component("dot", Dot)
Vue.component("LocalTable", LocalTable)

Vue.use(Extends)
Vue.use(mavonEditor)
Vue.use(permission)
Vue.use(ElementUI, {
  locale,
  size: "small"
})
Vue.config.productionTip = false

// const routerPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(error => error)
// }
// require('babel-polyfill')

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})