import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '../layout/Layout'

/* RetailMall */
import RetailMall from "../views/retail/mall/index"

/* PurchaseMall */
import PurchaseMall from "../views/mall/index"

/* Print */
import Print from "../views/print/index"

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/

export const constantRouterMap = [
  {
    path: '/login',
    meta: { title: '登录', noCache: true },
    component: () => import('@/views/login'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/features/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/features/401'),
    hidden: true
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/features/redirect')
      }
    ]
  },
  {
    path: "/help",
    component: () => import('@/views/help/index'),
    hidden: true
  },
  {
    path: "/qa",
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'index',
        component: () => import('@/views/qa/index'),
        name: '问题交流区',
        meta: { title: '问题交流区' }
      }
    ]
  },
  {
    path: "/h5/statistics",
    component: () => import('@/views/h5/statistics/index'),
    hidden: true,
    children: [{
      path: "main",
      component: () => import('@/views/h5/statistics/main'),
      name: 'H5统计分析',
      meta: { title: 'DMS统计分析' }
    }]
  },
  {
    path: '/print/:char',
    component: Print,
    name: "打印",
    meta: { title: '打印' }
  },
  {
    path: '/mall/retail',
    component: RetailMall,
    redirect: '/mall/retail/list',
    hidden: true,
    children: [{
      path: "list",
      component: () => import('@/views/retail/mall/contents/list'),
      name: '销售商城',
      meta: { title: '销售商城' }
    }, {
      path: "item/:id",
      component: () => import('@/views/retail/mall/contents/detail'),
      name: '销售商城-商品详情',
      meta: { title: '销售商城 - 商品详情' }
    },
    {
      path: "cart",
      component: () => import('@/views/retail/mall/order/cart'),
      name: '销售商城-购物车',
      meta: { title: '销售商城 - 购物车' }
    },
    {
      path: "cart/confirm",
      component: () => import('@/views/retail/mall/order/confirm'),
      name: '销售商城-订单确认',
      meta: { title: '销售商城 - 订单确认' }
    },
    {
      path: "cart/complete/:id",
      component: () => import('@/views/retail/mall/order/complete'),
      name: '销售商城-订单提交成功',
      meta: { title: '销售商城 - 订单提交成功' }
    },
    {
      path: "order",
      component: () => import('@/views/retail/mall/order/list'),
      name: '销售商城-订单管理',
      meta: { title: '销售商城 - 订单管理' }
    },
    {
      path: "order/:id",
      component: () => import('@/views/retail/mall/order/detail'),
      name: '销售商城-订单详情',
      meta: { title: '销售商城 - 订单详情' }
    }]
  },
  {
    path: '/mall',
    component: PurchaseMall,
    redirect: '/mall/home',
    hidden: true,
    children: [
      {
        path: "home",
        component: () => import('@/views/mall/home'),
        name: '采购商城',
        meta: { title: '采购商城' }
      },
      {
        path: "list",
        component: () => import('@/views/mall/contents/list'),
        name: '采购商城-商品目录',
        meta: { title: '采购商城 - 商品目录' }
      },
      {
        path: "item/:id",
        component: () => import('@/views/mall/contents/detail'),
        name: '采购商城-商品详情',
        meta: { title: '采购商城 - 商品详情' }
      },
      {
        path: "group/list",
        component: () => import('@/views/mall/contents/group/list'),
        name: '采购商城-组合销售',
        meta: { title: '采购商城 - 组合销售' }
      },
      {
        path: "group/item/:id",
        component: () => import('@/views/mall/contents/group/detail'),
        name: '采购商城-组合销售详情',
        meta: { title: '采购商城 - 组合销售详情' }
      },
      {
        path: "marketing",
        component: () => import('@/views/mall/contents/marketing'),
        name: '采购商城-促销活动',
        meta: { title: '采购商城 - 促销活动' }
      },
      {
        path: "cart",
        component: () => import('@/views/mall/order/cart'),
        name: '采购商城-购物车',
        meta: { title: '采购商城 - 购物车' }
      },
      {
        path: "cart/confirm",
        component: () => import('@/views/mall/order/confirm'),
        name: '采购商城-订单结算',
        meta: { title: '采购商城 - 订单结算' }
      },
      {
        path: "cart/complete/:id",
        component: () => import('@/views/mall/order/complete'),
        name: '采购商城-订单提交成功',
        meta: { title: '采购商城 - 订单提交成功' }
      },
      {
        path: "order",
        component: () => import('@/views/mall/order/list'),
        name: '采购商城-订单管理',
        meta: { title: '采购商城 - 订单管理' }
      },
      {
        path: "order/:id",
        component: () => import('@/views/mall/order/detail'),
        name: '采购商城-订单详情',
        meta: { title: '采购商城 - 订单详情' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home'),
        name: '首页',
        meta: { title: '首页', icon: 'fas fa-home', noCache: true, affix: true }
      }
    ]
  },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'center',
        component: () => import('@/views/system/user/center'),
        name: '个人中心',
        meta: { title: '个人中心', icon: 'user' }
      }
    ]
  }
  // { path: '*', redirect: '/404', hidden: true }
]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
