<template>
  <div class="el-select el-tree-picker" :class="[selectSize ? 'el-select--' + selectSize : '']" v-clickoutside="handleClose" v-if="picker">
    <el-input ref="reference" :value="selected ? selected[displayField] : ''" type="text" :placeholder="loading ? '正在加载中…' : currentPlaceholder" :name="name" :id="id" :size="selectSize" :disabled="disabled || loading" :readonly="true" :validate-event="false" :class="{ 'is-focus': visible }" @focus="handleFocus" @blur="handleBlur" @mousedown.native="handleMouseDown" @mouseenter.native="inputHovering = true" @mouseleave.native="inputHovering = false">
      <i slot="suffix" :class="['el-select__caret', 'el-input__icon', 'el-icon-' + iconClass]" @click="handleIconClick"></i>
    </el-input>

    <transition name="el-zoom-in-top" @after-leave="doDestroy">
      <el-select-menu ref="popper" class="tree-picker-popper" v-show="visible">
        <el-tree ref="tree" :data="store" :lazy="lazy" :load="lazyLoad" :accordion="accordion" :node-key="valueField" :props="{label: displayField, children: childrenField, isLeaf: isLeafField}" highlight-current :filter-node-method="handleFilter" :default-expanded-keys="expandKeys" @current-change="handleSelectChange" :expand-on-click-node="false" :style="{overflowX:'hidden', overflowY: 'auto', width: popperWidth && popperWidth > 0 ? `${popperWidth}px` : null, maxHeight: popperHeight && popperHeight > 0 ? `${popperHeight}px` : null}" />
      </el-select-menu>
    </transition>
  </div>
  <div class="el-tree-picker-inline" v-else>
    <el-tree ref="tree" :data="store" :lazy="lazy" :load="lazyLoad" :accordion="accordion" :node-key="valueField" :props="{label: displayField, children: childrenField, isLeaf: isLeafField}" highlight-current :filter-node-method="handleFilter" :default-expanded-keys="expandKeys" @current-change="handleSelectChange" :expand-on-click-node="false" />
  </div>
</template>

<script>
import { t } from "element-ui/src/locale";
import Clickoutside from "element-ui/src/utils/clickoutside";
import {
  addResizeListener,
  removeResizeListener
} from "element-ui/src/utils/resize-event";
import { addClass, removeClass, hasClass } from "element-ui/src/utils/dom";
import Emitter from "element-ui/src/mixins/emitter";
import Focus from "element-ui/src/mixins/focus";
import Locale from "element-ui/src/mixins/locale";
import { valueEquals } from "element-ui/src/utils/util";
import ElSelectMenu from "element-ui/packages/select/src/select-dropdown.vue";
import request from "@/utils/request";

export default {
  name: "CDatagridPicker",
  componentName: "CDatagridPicker",
  mixins: [Emitter, Locale, Focus("reference")],
  directives: { Clickoutside },
  components: { ElSelectMenu },
  props: {
    name: String,
    id: String,
    value: String | Number | Object,
    size: String,
    loadingText: String,
    noMatchText: String,
    noDataText: String,
    lazy: Boolean,
    options: Array,
    accordion: Boolean,
    picker: {
      type: Boolean,
      default: true
    },
    rootData: {
      type: Array,
      default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      default() {
        return t("el.select.placeholder");
      }
    },
    valueField: {
      type: String,
      default: "id"
    },
    displayField: {
      type: String,
      default: "name"
    },
    parentField: {
      type: String,
      default: "parentId"
    },
    childrenField: {
      type: String,
      default: "children"
    },
    isLeafField: {
      type: String,
      default: "isLeaf"
    },
    queryValueField: {
      type: String,
      default: null
    },
    excludeKeys: Array,
    params: Object,
    url: String,
    displayUrl: String,
    disabled: Boolean,
    clearable: Boolean,
    simpleValue: {
      type: Boolean,
      default: true
    },
    flat: Boolean,
    rootKey: {
      type: String | Object | Number,
      default: null
    },
    rootValue: {
      type: String | Object | Number,
      default: null
    },
    popperWidth: Number,
    popperHeight: {
      type: Number,
      default: 360
    }
  },
  data() {
    return {
      initing: false,
      inited: false,
      loading: false,
      visible: false,
      inputWidth: 0,
      currentPlaceholder: "",
      cachedPlaceHolder: "",
      inputHovering: false,
      total: 0,
      store: [],
      attachSelectionChange: true,
      selection: null,
      selected: null,
      page: 1,
      querying: false,
      canceller: null,
      expandKeys: []
    };
  },
  inject: {
    elFormItem: {
      default: ""
    }
  },
  provide() {
    return {
      select: this
    };
  },
  computed: {
    _elFormItemSize() {
      return (this.elFormItem || {}).elFormItemSize;
    },
    iconClass() {
      if (this.initing) return "loading";
      let criteria =
        this.clearable &&
        !this.disabled &&
        this.inputHovering &&
        this.value !== null &&
        this.value !== undefined &&
        this.value !== "";
      return criteria ? "circle-close is-show-close" : "arrow-up";
    },
    selectSize() {
      return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
    collapseTagSize() {
      return ["small", "mini"].indexOf(this.selectSize) > -1 ? "mini" : "small";
    }
  },
  methods: {
    toggleMenu() {
      if (!this.disabled) {
        this.visible = !this.visible;
        if (this.visible) {
          (this.$refs.input || this.$refs.reference).focus &&
            (this.$refs.input || this.$refs.reference).focus();
        }
      }
    },
    doDestroy() {
      this.$refs.popper && this.$refs.popper.doDestroy();
    },
    handleClose() {
      this.visible = false;
      if (this.$refs.reference) {
        this.$refs.reference.$refs.input &&
          this.$refs.reference.$refs.input.blur();
      }
    },
    handleFocus(event) {
      this.visible = true;
      this.$emit("focus", event);
    },
    handleBlur(event) {
      this.$emit("blur", event);
    },
    getFullInfo(value) {
      let ids = [],
        labels = [];
      if (this.$refs.tree) {
        let node = this.$refs.tree.getNode(value);
        while (node && node.level >= 1) {
          ids.unshift(node.data[this.valueField]);
          labels.unshift(node.data[this.displayField]);
          node = node.parent;
        }
      }
      return {
        values: ids,
        labels: labels
      };
    },
    handleIconHide() {
      let icon = this.$el.querySelector(".el-input__icon");
      if (icon) {
        removeClass(icon, "is-reverse");
      }
    },

    handleIconShow() {
      let icon = this.$el.querySelector(".el-input__icon");
      if (icon && !hasClass(icon, "el-icon-circle-close")) {
        addClass(icon, "is-reverse");
      }
    },
    handleMouseDown(event) {
      if (event.target.tagName !== "INPUT") return;
      if (this.visible) {
        this.handleClose();
        event.preventDefault();
      }
    },
    handleResize() {
      this.resetInputWidth();
      this.broadcast("ElSelectDropdown", "updatePopper");
    },
    handleIconClick(event) {
      if (this.iconClass.indexOf("circle-close") > -1) {
        this.deleteSelected(event);
      } else {
        this.toggleMenu();
      }
    },
    resetInputWidth() {
      if (this.picker)
        this.inputWidth = this.$refs.reference.$el.getBoundingClientRect().width;
    },
    handleSelectChange(v) {
      this.selected = v;
      this.updateValue();
    },
    updateValue() {
      let value = this.selected;
      if (this.simpleValue && this.$isPlainObject(this.selected)) {
        value = this.selected[this.valueField];
      }

      if (!valueEquals(this.value, value)) {
        this.$emit("input", value);
        this.dispatch("ElFormItem", "el.form.change", value);

        this.$emit("change", this.selected);
        this.visible = false;
      }
    },

    deleteSelected(event) {
      event.stopPropagation();
      this.selected = null;
      this.$refs.tree && this.$refs.tree.setCurrentKey(null);
      this.visible = false;
      this.updateValue();
      this.$emit("clear");
    },
    setSelected(value) {
      if (this.lazy) {
        if (!this.url) return;
        let vs = null;
        if (
          (this.simpleValue && value != null && value !== "") ||
          (!this.simpleValue && this.$isPlainObject(value))
        ) {
          vs = this.simpleValue ? value : value[this.valueField];
        }
        if (vs !== null) {
          this.initing = true;
          let o = Object.assign({ Page: 1, Size: 1 }, this.params || {});
          o[this.queryValueField || this.valueField] = vs;
          request({
            url: this.displayUrl || this.url,
            method: "get",
            params: o
          })
            .then(res => {
              if (this.$isArray(res.content, true)) {
                this.selected = res.content[0];
              } else if (this.$isPlainObject(res)) {
                this.selected = res;
              }
              if (this.selected) {
                this.$refs.tree &&
                  this.$refs.tree.setCurrentKey(this.selected[this.valueField]);
              } else {
                this.$refs.tree && this.$refs.tree.setCurrentKey(null);
              }
            })
            .finally(() => {
              this.initing = false;
            });
        }
      } else if (value) {
        this.$refs.tree && this.$refs.tree.setCurrentKey(value);
        this.selected = this.$refs.tree.getCurrentNode();
        this.expandKeys = [value];
      } else {
        this.$refs.tree && this.$refs.tree.setCurrentKey(null);
        this.selected = null;
      }
    },
    managePlaceholder() {
      if (this.currentPlaceholder !== "") {
        this.currentPlaceholder = this.$refs.input.value
          ? ""
          : this.cachedPlaceHolder;
      }
    },
    handleFilter(value, data, node) {
      let v = true;
      if (this.excludeKeys && this.excludeKeys.length) {
        v =
          node.parent.visible &&
          this.excludeKeys.indexOf(data[this.valueField]) < 0;
      }
      return v;
    },
    cleanData(source) {
      let found = false;
      if (this.excludeKeys && this.excludeKeys.length) {
        if (source && source.length) {
          for (let i = 0, l = source.length; i < l; i++) {
            let inx = this.excludeKeys.indexOf(source[i][this.valueField]);
            if (inx >= 0) {
              found = true;
              source.splice(i, 1);
              break;
            }
            found = this.cleanData(source[i][this.childrenField]);
            if (found) {
              break;
            }
          }
        }
      }
      return found;
    },
    load(cb) {
      if (this.url) {
        this.loading = true;
        this.inited = true;
        let o = Object.assign({}, this.params);
        request({
          url: this.url,
          method: "get",
          params: o
        })
          .then(res => {
            // this.cleanData(res.content);
            this.total = res.totalCount;
            this.store = this.flat
              ? this.$buildTree(res.content || res, this.rootKey, {
                  idField: this.valueField,
                  parentField: this.parentField,
                  childrenField: this.childrenField
                })
              : res.content || res;
            this.$nextTick(() => {
              this.querying = false;
              cb && cb();
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.options) {
        this.store = this.options;
        this.$nextTick(() => {
          cb && cb();
        });
      }
    },
    lazyLoad(node, resolve) {
      let params = {};
      params[this.parentField] = "";
      if (node && node.parent) {
        params[this.parentField] = node.data[this.valueField];
      } else if (this.rootData && this.rootData.length) {
        resolve(this.rootData);
        return;
      } else {
        params[this.parentField] = this.rootValue;
      }
      request({
        url: this.url,
        method: "get",
        params: params
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {});
    }
  },
  created() {
    this.cachedPlaceHolder = this.currentPlaceholder = this.placeholder;
  },
  mounted() {
    addResizeListener(this.$el, this.handleResize);
    if (!this.inited && !this.lazy) {
      this.load(_ => {
        this.setSelected(this.value);
      });
    } else {
      this.setSelected(this.value);
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.handleIconShow();
        this.broadcast("ElSelectDropdown", "updatePopper");
        this.$refs.tree && this.$refs.tree.filter();
      } else {
        this.handleIconHide();
        this.broadcast("ElSelectDropdown", "destroyPopper");
      }
    },
    placeholder(val) {
      this.cachedPlaceHolder = this.currentPlaceholder = val;
    },
    params: {
      handler: function(newParams, oldParams) {
        if (
          !valueEquals(newParams, oldParams) &&
          JSON.stringify(newParams) !== JSON.stringify(oldParams)
        ) {
          this.load(_ => {
            this.setSelected(this.value);
          });
        }
      },
      deep: true
    },
    value: function(val) {
      this.ownValue = val;
      this.setSelected(val);
    }
  },
  beforeDestroy() {
    if (this.$el && this.handleResize)
      removeResizeListener(this.$el, this.handleResize);
  }
};
</script>

<style lang="less">
.el-tree-picker-inline {
  border: #e4e7ed solid 1px;
  border-radius: 4px;
  overflow: auto;
}
.tree-picker-popper {
  z-index: 9999 !important;
}
.tree-picker-popper .el-tree-node__content {
  line-height: 34px;
  height: 34px;
}
.tree-picker-popper
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #f5f7fa;
  color: #1890ff;
  font-weight: bold;
}
</style>