<template>
  <el-radio-group v-model="ownValue" :border="border" @change="handleChange">
    <template v-if="button">
      <el-radio-button v-for="o in options" :key="o[valueField]" :label="o[valueField]">{{o[displayField]}}</el-radio-button>
    </template>
    <template v-else>
      <el-radio v-for="o in options" :key="o[valueField]" :label="o[valueField]">{{o[displayField]}}</el-radio>
    </template>
  </el-radio-group>
</template>

<script>
import Emitter from "element-ui/src/mixins/emitter";
import { valueEquals } from "element-ui/src/utils/util";

export default {
  mixins: [Emitter],
  props: {
    value: String | Number | Boolean,
    options: Array,
    valueField: {
      type: String,
      default: "id"
    },
    displayField: {
      type: String,
      default: "label"
    },
    button: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ownValue: this.value
    };
  },
  watch: {
    value: "handleValueSet"
  },
  methods: {
    handleChange(val) {
      if (valueEquals(this.value, val)) return;
      let ov = this.value;
      this.$emit("input", val);
      this.$emit("change", val, ov);
      this.dispatch("ElFormItem", "el.form.change", val, ov);
    },
    handleValueSet(v) {
      if (!valueEquals(this.ownValue, v)) {
        this.ownValue = v;
      }
    }
  }
};
</script>