<template>
  <div id="app">
    <router-view />
    <transition name="fade">
      <div ref="pBox" class="common-preview" @mousewheel="handlePreviewMouseWheel" v-show="preview.show">
        <template v-if="preview.source">
          <img ref="pImg" :src="calcedPreviewImageUrl" v-if="preview.source.image" @load="handlePreviewImageLoaded" :style="preview.style" draggable="false" @mousedown="handlePreviewMouseDown" @mouseup="handlePreviewMouseUp" />
          <video :src="preview.source.url" v-else-if="preview.source.video" autoplay controls></video>

          <i class="common-preview-close el-icon-circle-close" @click="closePreview"></i>

          <div class="common-preview-controls" v-if="preview.source.image">
            <div class="item v c" @click="handlePreviewImageRotate(90)">
              <i class="el-icon-refresh-right">
                <span>90</span>
              </i>
              <div>顺时针旋转</div>
            </div>
            <div class="item v c" @click="handlePreviewImageRotate(-90)">
              <i class="el-icon-refresh-left">
                <span>90</span>
              </i>
              <div>逆时针旋转</div>
            </div>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      preview: {
        show: false,
        source: null,
        loaded: false,
        top: 0,
        left: 0,
        boxWidth: 0,
        boxHeight: 0,
        originWidth: 0,
        originHeight: 0,
        startX: 0,
        startY: 0,
        offsetX: 0,
        offsetY: 0,
        xRange: 0,
        yRange: 0,
        minZoom: 0.5,
        maxZoom: 2,
        rotate: 0,
        style: {
          transform: "none",
          zoom: 1,
        },
      },
    };
  },
  computed: {
    calcedPreviewImageUrl() {
      if (this.preview.source) {
        // if (this.preview.rotate === 0) {
        return this.preview.source.url;
        // } else {
        //   return this.preview.source.url + "?x-oss-process=image/rotate," + this.preview.rotate;
        // }
      }
      return null;
    },
  },
  methods: {
    openPreview(obj) {
      this.preview.source = obj;
      this.preview.rotate = 0;
      this.preview.show = true;
    },
    closePreview() {
      this.preview.show = false;
      this.preview.source = null;
      this.preview.loaded = false;
      this.preview.offsetX = 0;
      this.preview.offsetY = 0;
    },
    handlePreviewImageLoaded() {
      this.preview.loaded = true;
      let box = this.$refs.pBox,
        el = this.$refs.pImg;

      let bw = box.clientWidth,
        bh = box.clientHeight,
        w = el.width,
        h = el.height;

      this.preview.originWidth = w;
      this.preview.originHeight = h;
      this.preview.boxWidth = bw;
      this.preview.boxHeight = bh;

      let wr = bw / w,
        hr = bh / h;
      if (wr > 1 && hr > 1) {
        this.preview.style.zoom = 1;
        this.preview.minZoom = 0.5;
      } else {
        this.preview.style.zoom = wr > hr ? hr : wr;
        this.preview.minZoom =
          this.preview.style.zoom < 0.5 ? this.preview.style.zoom : 0.5;
      }
      this.calcPreviewImageInfos();
    },
    calcPreviewImageInfos() {
      let cw = (this.preview.rotate % 180 === 0 ? this.preview.originWidth : this.preview.originHeight) * this.preview.style.zoom,
        ch = (this.preview.rotate % 180 !== 0 ? this.preview.originWidth : this.preview.originHeight) * this.preview.style.zoom;

      let xr = Math.round((cw - this.preview.boxWidth) / 2),
        yr = Math.round((ch - this.preview.boxHeight) / 2);

      if (xr < 0) xr = 0;
      if (yr < 0) yr = 0;

      if (this.preview.offsetX < -xr) this.preview.offsetX = -xr;
      if (this.preview.offsetX > xr) this.preview.offsetX = xr;
      if (this.preview.offsetY < -yr) this.preview.offsetY = -yr;
      if (this.preview.offsetY > yr) this.preview.offsetY = yr;

      this.preview.xRange = xr;
      this.preview.yRange = yr;
      this.preview.style.transform = `translate(${this.preview.offsetX}px, ${this.preview.offsetY}px) rotate(${this.preview.rotate}deg)`;
    },
    handlePreviewMouseDown(e) {
      let el = this.$refs.pBox;
      if (el) {
        el.removeEventListener("mousemove", this.handlePreviewMouseMove, true);
        if (!this.preview.source || !this.preview.source.image) return false;
        if (
          this.preview.calcWidth < this.preview.boxWidth &&
          this.preview.calcHeight < this.preview.boxHeight
        )
          return false;
        this.preview.startX = e.pageX;
        this.preview.startY = e.pageY;
        el.addEventListener("mousemove", this.handlePreviewMouseMove, true);
      }
    },
    handlePreviewMouseMove(e) {
      let nx = this.preview.offsetX + e.pageX - this.preview.startX,
        ny = this.preview.offsetY + e.pageY - this.preview.startY;
      if (nx < -this.preview.xRange) nx = -this.preview.xRange;
      if (nx > this.preview.xRange) nx = this.preview.xRange;
      if (ny < -this.preview.yRange) ny = -this.preview.yRange;
      if (ny > this.preview.yRange) ny = this.preview.yRange;
      this.preview.offsetX = nx;
      this.preview.offsetY = ny;
      this.preview.startX = e.pageX;
      this.preview.startY = e.pageY;
      // this.preview.style.transform = `translate(${this.preview.offsetX}px, ${this.preview.offsetY}px) rotate(${this.preview.rotate}deg)`;
      this.calcPreviewImageInfos();
      return false;
    },
    handlePreviewMouseUp(e) {
      let el = this.$refs.pBox;
      if (el) {
        el.removeEventListener("mousemove", this.handlePreviewMouseMove, true);
      }
    },
    handlePreviewMouseWheel(e) {
      let step = e.wheelDelta > 0 ? 0.1 : -0.1;
      let nr = this.preview.style.zoom + step;
      if (nr > this.preview.maxZoom) nr = this.preview.maxZoom;
      if (nr < this.preview.minZoom) nr = this.preview.minZoom;
      this.preview.style.zoom = nr;
      this.calcPreviewImageInfos();
    },
    handlePreviewImageRotate(step) {
      let r = this.preview.rotate + step;
      if (r > 360) r -= 360;
      if (r < 0) r += 360;
      this.preview.rotate = r;
      this.calcPreviewImageInfos();
    },
  },
  mounted() {
    window.appRoot = this;
    window.onresize = (_) => {
      if (
        this.preview.show &&
        this.preview.source &&
        this.preview.source.image &&
        this.preview.loaded
      ) {
        //this.calcPreviewImagePosition();
      }
    };
  },
};
</script>

<style lang="less">
#app .common-preview {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999999;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;

  img {
    cursor: move;
  }

  video {
    width: 50%;
    height: 60%;
  }

  &-close {
    position: absolute;
    z-index: 10;
    right: 20px;
    top: 20px;
    font-size: 32px;
    color: white;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  &-controls {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;

    .item {
      font-size: 12px;
      padding: 10px;
      margin: 0 10px;
      border-radius: 5px;
      cursor: pointer;
      opacity: 0.6;
      background-color: rgba(0, 0, 0, 0.6);
      transition: all 0.3s;

      &:hover {
        opacity: 1;
      }

      i {
        position: relative;
        font-size: 32px;
        margin-bottom: 5px;

        > span {
          font-size: 12px;
          position: absolute;
          left: 0;
          right: 0;
          text-align: center;
          line-height: 32px;
          transform: scale(0.8);
        }
      }
    }
  }
}
</style>