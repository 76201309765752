<template>
  <div class="app-mall app-retail-mall">
    <a ref="topAnchor"></a>
    <div class="shop-body">
      <div ref="header" class="container padding-20-0">
        <div class="content h c">
          <div>
            <div class="logo">销售商城</div>
            <div>{{shop.name}}</div>
          </div>
          <div class="flex"></div>
          <div class="search-box h">
            <i class="fas fa-search"></i>
            <input v-model.trim="keyword" placeholder="输入商品名称" @keypress.enter="handleQuery" />
            <button @click="handleQuery">搜索</button>
          </div>
        </div>
      </div>
      <div ref="navigate" class="container navigation">
        <div class="content h">
          <router-link v-for="n in navigation" :key="n.path" :to="getMenuPath(n)" :class="{selected: $route.path.indexOf(n.path) === 0}">{{n.name}}</router-link>
        </div>
      </div>
      <template v-if="shop.current">
        <keep-alive>
          <router-view :shop-id="shop.current" ref="content" />
        </keep-alive>
      </template>
    </div>
    <div class="container bc-l">
      <div class="content padding-20"></div>
    </div>
    <el-dialog :visible="!shop.current" :close-on-click-modal="false" :show-close="false" :close-on-press-escape="false" title="选择门店" width="480px">
      <div style="margin-bottom: 12px;">请选择当前进行销售的门店：</div>
      <quick-select v-model="shop.select" :label.sync="shop.name" filterable :url="`api/shop?enabled=true`" value-field="id" display-field="shopName" placeholder="选择销售门店" auto-select-first-option style="width: 100%;" />
      <div slot="footer">
        <el-button :disabled="!shop.select" type="primary" @click="shop.current = shop.select">确定</el-button>
      </div>
    </el-dialog>
    <transition name="fade">
      <div class="topper" v-show="showTopper">
        <div class="content">
          <i class="fas fa-arrow-up" @click="handleScrollToTop"></i>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import { mapGetters } from "vuex";
import request from "@/utils/request";

export default {
  data() {
    return {
      homePagePath: "/mall/retail/home",
      keyword: "",
      showTopper: false,
      shop: {
        select: null,
        current: null,
        name: null,
      },
      navigation: [
        {
          id: "list",
          name: "商品目录",
          path: "/mall/retail/list",
        },
        {
          id: "cart",
          name: "购物车",
          path: "/mall/retail/cart",
        },
        {
          id: "order",
          name: "订单管理",
          path: "/mall/retail/order",
        },
      ],
      cart: JSON.parse(window.localStorage.getItem("retail_cart") || "{}"),
      cartTotal: 0,
      history: JSON.parse(
        window.localStorage.getItem("retail_history") || "{}"
      ),
    };
  },
  computed: {
    ...mapGetters(["user"]),
    cartKey() {
      if (this.user) return this.user.entId;
      else return "_default";
    },
  },
  watch: {
    cart: {
      deep: true,
      handler: "calcCartTotal",
    },
  },
  methods: {
    checkPermission,
    getMenuPath(r) {
      let p = r.path;
      if (r.id === "list") {
        let filters = JSON.parse(
          window.localStorage.getItem("retail-mall-filters") || "{}"
        );
        if (filters && filters.length) {
          let map = [];
          for (let k in filters) {
            if (filters.hasOwnProperty(k)) {
              map.push(`${k}=${filters[k]}`);
            }
          }
          p = p + "?" + map.join("&");
        }
      }
      return p;
    },
    handleScroll(e) {
      if (e.target.scrollTop >= 200) {
        this.showTopper = true;
      } else {
        this.showTopper = false;
      }
      let cel = this.$refs.content;
      if (cel && cel.onScroll) {
        let hs =
          this.$refs.header.offsetHeight + this.$refs.navigate.offsetHeight;
        cel.onScroll(e.target.scrollTop, hs);
      }
    },
    handleScrollToTop(e) {
      this.$refs.topAnchor && this.$refs.topAnchor.scrollIntoView(true);
    },
    handleQuery() {
      if (this.keyword) {
        this.$router.push({
          path: "/mall/retail/list?kw=" + encodeURIComponent(this.keyword),
        });
      } else {
        this.$router.push({
          path: "/mall/retail/list",
        });
      }
    },
    calcCartTotal() {
      let nums = 0;
      if (this.cart.hasOwnProperty(this.cartKey)) {
        this.cart[this.cartKey].forEach((c) => {
          nums += c.times;
        });
      }
      this.cartTotal = nums;
    },
    handleCart() {
      this.$router.push({
        path: "/mall/retail/cart",
      });
    },
    handleCartGet() {
      return this.cart[this.cartKey] || [];
    },
    handleCartAdd(item) {
      if (item && item.id) {
        if (!this.cart.hasOwnProperty(this.cartKey)) {
          this.cart[this.cartKey] = [];
        }
        let store = this.cart[this.cartKey];
        let exist = store.find((c) => {
          return c.id === item.id;
        });
        if (exist) exist.times += item.times;
        else store.push(Object.assign(item, { _checked: false }));
        this.handleCartSave();
        this.$message.success("加入购物车成功");
      }
    },
    handleCartRemove(item) {
      if (item) {
        let ds = this.cart[this.cartKey];
        let inx = ds.findIndex((s) => {
          return s.id === item.id;
        });
        if (inx >= 0) {
          ds.splice(inx, 1);
          this.$message.success("从购物车移除商品成功");
          this.handleCartSave();
        }
      }
    },
    handleCartClear(clearAll = true) {
      if (clearAll) {
        this.cart[this.cartKey] = [];
      } else {
        let cs = this.cart[this.cartKey];
        if (cs && cs.length)
          this.cart[this.cartKey] = cs.filter((o) => {
            return !o._checked;
          });
      }
      this.handleCartSave();
    },
    handleCartSave() {
      window.localStorage.setItem("retail_cart", JSON.stringify(this.cart));
    },
    handleHistoryGet() {
      if (this.history.hasOwnProperty(this.user.entId)) {
        return this.history[this.user.entId];
      } else {
        return [];
      }
    },
    handleHistoryAdd(goods) {
      if (!this.history.hasOwnProperty(this.user.entId)) {
        this.history[this.user.entId] = [];
      }
      let inx = this.history[this.user.entId].findIndex((o) => {
        return o.id === goods.id;
      });
      if (inx >= 0) this.history[this.user.entId].splice(inx, 1);
      this.history[this.user.entId].unshift(goods);
      if (this.history[this.user.entId].length > 100) {
        this.history[this.user.entId].length = 100;
      }
      window.localStorage.setItem(
        "retail_history",
        JSON.stringify(this.history)
      );
    },
  },
  mounted() {
    if (this.checkPermission(["MARKET_ALL", "MARKET_SALES"])) {
      request({
        url: "api/check/erp",
        method: "get",
      }).then((res) => {
        this.$erp.enable(res === true);
      });

      request({
        url: "api/current/time",
        method: "get",
      }).then((res) => {
        this.$now.set(res || new Date().getTime());
      });

      window.localStorage.removeItem("retail-mall-filters");
      this.$el.addEventListener("scroll", this.handleScroll);
      this.handleCartClear(true);
      window.mall = {
        cart: {
          get: this.handleCartGet,
          add: this.handleCartAdd,
          remove: this.handleCartRemove,
          clear: this.handleCartClear,
          save: this.handleCartSave,
        },
        history: {
          get: this.handleHistoryGet,
          add: this.handleHistoryAdd,
        },
      };
    } else {
      this.$router.replace("/404");
    }
  },
};
</script>

<style lang="less">
@import "../../../styles/shop.less";
</style>