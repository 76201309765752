<template>
  <div class="navbar h">
    <hamburger :toggle-click="toggleSideBar" :is-active="sidebar.opened" class="hamburger-container" />
    <breadcrumb class="breadcrumb-container flex" />
    <user-switch ref="switcher" />

    <div class="right-menu h c">
      <img :src="user.avatar ? baseApi + '/avatar/' + user.avatar : Avatar" class="user-avatar" />
      <div class="v" style="line-height: 1.25;">
        <span class="padding-0-10">{{user.userRealName}}，欢迎回来！</span>
        <span class="padding-0-10 fc-g" style="font-size: 12px;">{{user.entName}}</span>
      </div>
      <template v-if="oaLoginId && checkPermission(['OA_ALL','OA_USE'])">
        <el-divider direction="vertical" />
        <a href="javascript:void(0)" @click="toOA">
          <i class="fas fa-briefcase"></i>&nbsp;OA办公
        </a>
      </template>
      <el-divider direction="vertical" v-if="checkPermission(['MARKET_ALL','MARKET_SALES','MARKET_PURCHASE'])" />
      <a href="/mall/retail" target="retail_mall" v-permission="['MARKET_ALL','MARKET_SALES']">
        <i class="fas fa-shopping-bag"></i>&nbsp;销售商城
      </a>
      <a href="/mall/home" target="purchase_mall" v-permission="['MARKET_ALL','MARKET_PURCHASE']">
        <i class="fas fa-shopping-cart"></i>&nbsp;采购商城
      </a>
      <el-divider direction="vertical" />
      <template v-if="device!=='mobile'">
        <el-tooltip content="帮助中心" effect="dark" placement="bottom">
          <i class="fc-g fa fa-question menu" @click="handleHelp"></i>
        </el-tooltip>
        <el-tooltip content="问题交流区" effect="dark" placement="bottom">
          <i class="fc-g far fa-comment-dots menu" @click="handleQA"></i>
        </el-tooltip>
        <el-tooltip content="全屏" effect="dark" placement="bottom">
          <i class="fc-g fa fa-expand menu" @click="handleScreen"></i>
        </el-tooltip>
      </template>
      <el-dropdown trigger="hover" @command="handleCommand">
        <i class="fc-g fa fa-ellipsis-v menu"></i>
        <el-dropdown-menu slot="dropdown">
          <!-- <a target="_blank" href="https://docs.auauz.net/">
            <el-dropdown-item>
              项目文档
            </el-dropdown-item>
          </a>-->
          <!-- <span style="display:block;" @click="show = true">
            <el-dropdown-item>
              布局设置
            </el-dropdown-item>
          </span>-->

          <el-dropdown-item command="profile">个人中心</el-dropdown-item>
          <el-dropdown-item command="off" v-if="user.cachedToken">退出账号</el-dropdown-item>
          <el-dropdown-item command="switch" v-permission="['SYSTEM_ALL','SYSTEM_CHANGEACCOUNT']" v-else>切换账号</el-dropdown-item>
          <el-dropdown-item command="exit" divided>退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <update-pass must ref="pwd" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import screenfull from "screenfull";
import Avatar from "@/assets/avatar/avatar.png";
import UserSwitch from "./UserSwitch";
import { oaLogin } from "@/api/login";

import updatePass from "@/views/system/user/center/updatePass";

export default {
  components: {
    Breadcrumb,
    Hamburger,
    UserSwitch,
    updatePass,
  },
  data() {
    return {
      Avatar: Avatar,
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(["sidebar", "user", "cachedToken", "device", "baseApi"]),
    show: {
      get() {
        return this.$store.state.settings.showRightPanel;
      },
      set(val) {
        this.$store.dispatch("changeSetting", {
          key: "showRightPanel",
          value: val,
        });
      },
    },
    oaLoginId() {
      return process.env.NODE_ENV === "production"
        ? this.user.erpCode
        : "123321";
    },
  },
  methods: {
    checkPermission,
    handleScreen() {
      if (!screenfull.enabled) {
        this.$message({
          message: "you browser can not work",
          type: "warning",
        });
        return false;
      }
      screenfull.toggle();
    },
    handleHelp() {
      let a = document.createElement("a");
      a.href = "/help";
      a.target = "_blank";
      a.click();
    },
    handleQA() {
      this.$router.push({ path: "/qa/index" });
    },
    handleCommand(cmd) {
      switch (cmd) {
        case "profile":
          this.$router.push({
            path: "/user/center",
          });
          break;
        case "switch":
          this.$refs.switcher && this.$refs.switcher.show();
          break;
        case "off":
          this.$confirm("确定要退出当前账号并切回之前的账号吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.switchOff();
          });
          break;
        case "exit":
          this.$confirm("确定注销并退出系统吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.logout();
          });
          break;
      }
    },
    toggleSideBar() {
      this.$store.dispatch("ToggleSideBar");
    },
    logout() {
      this.dialogVisible = false;
      this.$store.dispatch("LogOut").then(() => {
        location.href = "/";
        //location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    },
    switchOff() {
      this.$store.dispatch("SwitchOff").then((_) => {
        location.href = "/";
      });
    },
    toOA() {
      if (this.oaLoginId) {
        let loader = this.$loading({
          lock: true,
          text: "正在登录OA系统，请稍候…",
        });
        oaLogin({
          erpCode: this.oaLoginId,
        })
          .then((res) => {
            if (res.host && res.token && /^[0-9A-Z]+$/gi.test(res.token)) {
              window.open(
                res.host +
                  "/spa/crmPortal/mobx/index.html?ssoToken=" +
                  res.token +
                  "&logintype=2#/main/document/news",
                "oa"
              );
            } else {
              throw new Error(res.token);
            }
          })
          .catch((e) => {
            this.$alert(
              `登录OA办公系统失败（${err.message || "原因未知，请稍候重试"}）`,
              "登录失败",
              {
                type: "error",
              }
            );
          })
          .finally(() => {
            loader.close();
          });
      }
    },
  },
  mounted() {
    if (this.user && this.user.defaultPass) {
      this.$refs.pwd && (this.$refs.pwd.dialog = true);
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    padding: 0 10px;
  }
  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }
  .right-menu {
    > a {
      display: block;
      padding: 0 20px;
      transition: all 0.3s;
      &:hover {
        background-color: #409eff;
        color: white;
      }
    }
    i.menu {
      display: block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
      outline: none;
      transition: all 0.3s;
      &:hover {
        background-color: #f8f8f9;
        color: #409eff !important;
      }
    }
    .right-menu-item {
      display: inline-block;
      margin: 0 8px;
    }
    .screenfull {
      height: 24px;
    }
    .international {
      vertical-align: top;
    }
    .theme-switch {
      vertical-align: 15px;
    }
    .user-avatar {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
