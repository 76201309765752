<template>
  <div class="component-form-info-item" :class="labelPosition || (form && form.labelPosition)">
    <div class="_title" :style="labelStyle" v-if="label">{{label}}{{labelSuffix || (form && form.labelSuffix)}}</div>
    <div class="_content" :class="inline ? '_inline' : ''" :style="contentStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    labelWidth: String,
    inline: Boolean,
    labelSize: String,
    labelSuffix: String,
    contentStyle: Object | String,
    labelPosition: String
  },
  computed: {
    labelStyle() {
      const ret = { fontSize: this.labelSize };
      if (this.form && this.form.labelPosition === "top") return ret;
      const labelWidth = this.labelWidth || (this.form && this.form.labelWidth);
      if (labelWidth) {
        ret.width = labelWidth;
      }
      return ret;
    },
    form() {
      let parent = this.$parent;
      let parentName = parent.$options.componentName;
      while (parent && parentName !== "ElForm") {
        if (parentName === "ElFormItem") {
          this.isNested = true;
        }
        parent = parent.$parent;
        parentName =
          parent && parent.$options ? parent.$options.componentName : null;
      }
      return parent;
    }
  }
};
</script>