<template>
  <div class="v main_left_panel" :class="{collapse: isCollapse}">
    <div class="logo">
      <img src="/static/img/logo-collapse.png" class="default-logo" v-if="isCollapse" />
      <img src="/static/img/logo.png" class="default-logo" v-else />
    </div>
    <div class="menus-box flex" style="overflow-x: hidden; overflow-y: scroll;">
      <el-menu :show-timeout="200" :default-active="$route.path" :collapse="isCollapse" router :unique-opened="$store.state.settings.uniqueOpened" mode="vertical" background-color="transparent" text-color="#bfcbd9" active-text-color="#ffffff">
        <template v-for="route in permission_routers">
          <sidebar-item :key="route.path" :item="route" v-if="!route.hidden && route.children && route.children.length" />
        </template>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";
import Logo from "./Logo";
export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters(["permission_routers", "sidebar"]),
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
};
</script>

<style lang="less">
.el-menu--vertical {
  background-color: #3a6cab;

  .el-menu-item:hover,
  .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
}

.main_left_panel {
  background: linear-gradient(to bottom, #19467e, #3a6cab);

  &.collapse {
    width: 56px;
    .el-menu {
      .el-menu-icon {
        margin: 0;
      }
    }

    + .main-container {
      margin-left: 56px;
    }

    .logo {
      padding: 10px;
    }
  }

  .logo {
    padding: 20px;
    .default-logo {
      width: 100%;
    }
  }

  .menus-box {
    margin-right: -18px;
    &::-webkit-scrollbar {
      width: 18px;
      height: 18px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .el-menu {
      border: none;

      > .el-submenu.is-opened {
        background-color: rgba(0, 0, 0, 0.1);
      }

      .el-menu-icon {
        display: inline-block;
        color: rgba(255, 255, 255, 0.3) !important;
        font-size: 12px;
        width: 16px;
        text-align: center;
      }

      .el-submenu__icon-arrow {
        margin-top: -5px;
        color: rgba(255, 255, 255, 0.6);
      }

      span.el-menu-title {
        margin-left: 4px;
        font-size: 13px;
      }
      .el-menu-item.is-active {
        color: white;
        font-weight: bold;
        .el-menu-icon {
          color: white !important;
        }
      }

      .el-menu-item:hover,
      .el-submenu__title:hover {
        background-color: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }
}
</style>