<template>
  <el-input :size="size" placeholder="正在加载中…" readonly :disabled="disabled" v-if="loading"></el-input>
  <el-select v-else :size="size" :placeholder="placeholder" v-model="ownValue" :value-key="valueField" :multiple="multiple" :filterable="filterable" :allow-create="allowCreate" :default-first-option="defaultFirstOption" :collapse-tags="collapseTags" :loading="customLoading || loading" :disabled="disabled" :clearable="clearable" :no-data-text="emptyText" :filter-method="calcedFilterMethod" @change="handleChange" @clear="handleClear" @visible-change="handleVisibleChange">
    <el-option v-for="item in source" :key="item[valueField]" :disabled="checkOptionDisabled(item)" :label="item[displayField]" :value="item[valueField]">
      <slot v-bind:row="item"></slot>
    </el-option>
  </el-select>
</template>

<script>
import Emitter from "element-ui/src/mixins/emitter";
import { valueEquals } from "element-ui/src/utils/util";
import request from "@/utils/request";

export default {
  mixins: [Emitter],
  props: {
    value: String | Number | Array,
    url: String,
    options: Array,
    appends: Array,
    multiple: Boolean,
    label: String | Array,
    valueField: { type: String, default: "id" },
    displayField: { type: String, default: "name" },
    emptyText: { type: String, default: "无数据" },
    params: Object | String | Number,
    pagable: {
      type: Boolean,
      default: true
    },
    customLoading: Boolean,
    filterable: Boolean,
    filterMethod: Function,
    allowCreate: Boolean,
    collapseTags: Boolean,
    defaultFirstOption: Boolean,
    autoSelectFirstOption: Boolean,
    autoSelectSingleOption: Boolean,
    disabledOption: Function,
    placeholder: String,
    disabled: Boolean,
    clearable: Boolean,
    size: String,
    beforeChange: Function
  },
  data() {
    return {
      ownValue: null,
      loading: false,
      store: [],
      source: [],
      canceller: null
    };
  },
  methods: {
    calcedFilterMethod(v) {
      if (v) {
        v = String(v).toLowerCase();
        if (typeof this.filterMethod === "function")
          this.source = this.filterMethod(this.store, v);
        else
          this.source = this.store.filter(o => {
            return (o[this.displayField] || "").toLowerCase().indexOf(v) >= 0;
          });
      } else {
        this.source = this.store;
      }
    },
    load(update = true) {
      if (this.url) {
        let o = this.pagable
          ? {
              page: 0,
              size: 2000
            }
          : {};
        if (typeof this.params === "string") {
          o = this.params;
        } else if (this.$isPlainObject(this.params)) {
          o = Object.assign(o, this.params);
        }
        this.loading = true;
        request({
          url: this.url,
          method: "get",
          params: o
        })
          .then(res => {
            let opts = this.$isArray(res) ? res : res.content;
            let filterAppends = this.appends || [];
            filterAppends = filterAppends.filter(fa => {
              return (
                fa &&
                fa[this.valueField] != null &&
                opts.findIndex(opt => {
                  return opt[this.valueField] === fa[this.valueField];
                }) < 0
              );
            });
            this.store = filterAppends.concat(opts);
            this.source = this.store;
            if (
              this.store &&
              this.store.length &&
              (this.autoSelectFirstOption ||
                (this.store.length === 1 && this.autoSelectSingleOption)) &&
              (this.value == null ||
                this.store.findIndex(o => {
                  return o[this.valueField] === this.value;
                }) < 0)
            ) {
              this.ownValue = this.store[0][this.valueField];
            } else {
              this.ownValue = this.value;
            }
            this.updateValue(this.ownValue, true);
          })
          .catch(err => {
            this.store = [];
            this.source = [];
          })
          .finally(_ => {
            this.loading = false;
          });
      } else {
        this.store = this.options;
        this.source = this.options;
        this.ownValue = this.value;
        if (
          this.store &&
          this.store.length &&
          (this.autoSelectFirstOption ||
            (this.store.length === 1 && this.autoSelectSingleOption)) &&
          (this.value == null ||
            this.store.findIndex(o => {
              return o[this.valueField] === this.value;
            }) < 0)
        ) {
          this.ownValue = this.store[0][this.valueField];
        } else {
          this.ownValue = this.value;
        }
        if (update) this.updateValue(this.ownValue, true);
      }
    },
    handleChange(val) {
      if (!valueEquals(this.value, val)) {
        if (this.beforeChange && typeof this.beforeChange === "function") {
          this.beforeChange(this.value, val)
            .then(_ => {
              this.updateValue(val);
              this.dispatch("ElFormItem", "el.form.change", val);
            })
            .catch(_ => {
              this.ownValue = this.value;
            });
        } else {
          this.updateValue(val);
          this.dispatch("ElFormItem", "el.form.change", val);
        }
      }
    },
    handleClear() {
      //this.updateValue(this.ownValue);
      this.$emit("clear");
    },
    handleVisibleChange(v) {
      if (!v) this.source = this.store;
    },
    checkOptionDisabled(item) {
      if (this.disabledOption) {
        return this.disabledOption(item);
      }
      return false;
    },
    getDisplayText(val) {
      let t = "";
      if (this.store && this.store.length) {
        let item = this.store.find(s => {
          return s[this.valueField] === val;
        });
        if (item) t = item[this.displayField];
      }
      return t;
    },
    getValue(text) {
      let v = null;
      if (this.store && this.store.length) {
        let item = this.store.find(s => {
          return s[this.displayField] === text;
        });
        if (item) v = item[this.valueField];
      }
      return v;
    },
    updateValue(val, isInit = false) {
      let labels = [],
        objects = [],
        vals = val;
      if (!this.$isArray(val)) {
        vals = [val];
      }
      if (vals && vals.length) {
        for (let v of vals) {
          let o =
            this.store && this.store.length
              ? this.store.find(s => {
                  return s[this.valueField] === v;
                })
              : null;
          objects.push(o);
          if (o) {
            labels.push(o[this.displayField] || "");
          } else {
            labels.push("");
          }
        }
      }
      if (!this.multiple) {
        labels = labels.length ? labels[0] : "";
        objects = objects.length ? objects[0] : null;
      }
      this.$emit("update:label", labels);
      if (isInit) this.$emit("inited", val, null, objects);
      //debugger
      if (valueEquals(this.value, val)) return;

      this.$emit("input", val);
      this.$emit("change", val, this.value, objects);
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    url: "load",
    params: {
      handler: function(newParams, oldParams) {
        if (
          !valueEquals(newParams, oldParams) &&
          JSON.stringify(newParams) !== JSON.stringify(oldParams)
        ) {
          this.load(false);
        }
      },
      deep: true
    },
    value: function(val) {
      this.ownValue = val;
      this.updateValue(val);
    },
    options: {
      deep: true,
      handler: function(newParams, oldParams) {
        if (!this.url) {
          this.store = newParams;
          this.source = newParams;
        }
      }
    }
  }
};
</script>
