import { initData } from '@/api/data'

export default {
  data() {
    return {
      loading: false, data: [], page: 0, size: 10, total: 0, url: '', params: {}, query: {}, time: 170, isAdd: false, delLoading: false
    }
  },
  methods: {
    _updateTableLayout(comps) {
      if (comps && comps.length) {
        comps.forEach(comp => {
          if (comp.$options && comp.$options.name === "ElTable") {
            comp.doLayout();
          } else {
            this._updateTableLayout(comp.$children);
          }
        });
      }
    },
    async init() {
      if (!await this.beforeInit()) {
        return
      }
      this.data = [];
      return new Promise((resolve, reject) => {
        this.loading = true
        initData(this.url, Object.assign({ page: this.page, size: this.size }, this.params)).then(res => {
          let _res = this.$isArray(res) ? res : res.content;
          let _nres = this.afterLoad(_res);
          this.afterLoadFull(res);
          this.data = _nres || _res;
          this.total = res.length || res.totalElements

          this.loading = false
          resolve(_res)
          this.$nextTick(() => {
            this._updateTableLayout(this.$children);
          });
        }).catch(err => {
          this.loading = false
          reject(err)
        })
      })
    },
    async lazyLoad(tree, resolve) {
      if (!await this.beforeInit(tree)) {
        resolve([]);
        return;
      }
      initData(this.url, Object.assign({ page: this.page, size: this.size }, this.params)).then(res => {
        let _res = this.$isArray(res) ? res : res.content;
        let _nres = this.afterLoad(_res);
        _res = _nres || _res;
        resolve(_res || []);
      }).catch(err => {
        resolve([]);
      });
    },
    beforeInit() {
      return true
    },
    afterLoad() {
    },
    afterLoadFull() {
    },
    pageChange(e) {
      this.page = e - 1
      this.init()
    },
    sizeChange(e) {
      this.page = 0
      this.size = e
      this.init()
    },
    // 预防删除第二页最后一条数据时，或者多选删除第二页的数据时，页码错误导致请求无数据
    dleChangePage(size) {
      if (size === undefined) {
        size = 1
      }
      if (this.data.length === size && this.page !== 0) {
        this.page = this.page - 1
      }
    },
    toQuery() {
      this.page = 0
      this.init()
    }
  }
}
