import Cookies from 'js-cookie'
import Config from '@/config'

const TokenKey = Config.TokenKey
const CachedTokenKey = Config.CachedTokenKey

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token, rememberMe) {
  if (rememberMe) {
    return Cookies.set(TokenKey, token, { expires: Config.tokenCookieExpires })
  } else return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getCachedToken () {
  return Cookies.get(CachedTokenKey)
}

export function setCachedToken () {
  let token = Cookies.get(TokenKey);
  if (token) {
    return Cookies.set(CachedTokenKey, token)
  }
  return null;
}

export function removeCachedToken () {
  return Cookies.remove(CachedTokenKey)
}