<template>
  <el-menu-item v-if="!item.children || !item.children.length" :index="basePath + item.path">
    <i class="el-menu-icon" :class="item.meta.icon" v-if="item.meta.icon"></i>
    <span class="el-menu-title" slot="title">{{item.meta.title}}</span>
  </el-menu-item>
  <el-menu-item v-else-if="item.path === '/' && item.children.length === 1" :index="basePath + '/' + item.children[0].path">
    <i class="el-menu-icon" :class="item.children[0].meta.icon" v-if="item.children[0].meta.icon"></i>
    <span class="el-menu-title" slot="title">{{item.children[0].meta.title}}</span>
  </el-menu-item>
  <el-submenu v-else :index="basePath + item.path">
    <template slot="title">
      <i class="el-menu-icon" :class="item.meta.icon" v-if="item.meta.icon"></i>
      <span class="el-menu-title" slot="title">{{item.meta.title}}</span>
    </template>
    <template v-for="route in item.children">
      <sidebar-item :key="route.path" :item="route" :base-path="basePath + item.path + '/'" v-if="!route.hidden" />
    </template>
  </el-submenu>

  <!-- <div v-if="!item.hidden && item.children">
    <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
      <app-link :to="resolvePath(onlyOneChild.path)">
        <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}">
          <template slot="title">
            <i :class="onlyOneChild.meta && onlyOneChild.meta.icon" style="width: 16px; text-align: center; margin-right: 4px; opacity: .6;"></i>
            <span slot="title">{{onlyOneChild.meta && onlyOneChild.meta.title}}</span>
          </template>
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu v-else-if="item._show !== false" ref="submenu" :index="resolvePath(item.path)">
      <template slot="title">
        <i :class="item.meta && item.meta.icon" style="width: 16px; text-align: center; margin-right: 4px; opacity: .6;"></i>
        <span slot="title">{{item.meta && item.meta.title}}</span>
      </template>

      <template v-for="child in item.children" v-if="!child.hidden">
        <sidebar-item v-if="child.children&&child.children.length>0" :is-nest="true" :item="child" :key="child.path" :base-path="resolvePath(child.path)" class="nest-menu" />

        <app-link v-else :to="resolvePath(child.path)" :key="child.name">
          <el-menu-item :index="resolvePath(child.path)">
            <template slot="title" v-if="child.meta">
              <span slot="title">&nbsp;{{child.meta && child.meta.title}}</span>
            </template>
          </el-menu-item>
        </app-link>
      </template>
    </el-submenu>
  </div>-->
</template>

<script>
import path from "path";
import { isExternal } from "@/utils";
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";

export default {
  name: "SidebarItem",
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      onlyOneChild: null
    };
  },
  methods: {
    hasOneShowingChild(children, parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }

      return false;
    },
    resolvePath(routePath) {
      if (this.isExternalLink(routePath)) {
        return routePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    isExternalLink(routePath) {
      return isExternal(routePath);
    }
  }
};
</script>
