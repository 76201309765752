/**
 * 用于封装一些常用的调用方法
 */
import qs from "qs"

let _erp_using = false;
let _sys_now = null,
  _sys_now_timer = null,
  _sys_now_update = function () {
    _sys_now += 60000;
  };
let _sys_ent_type = null;

function _build_tree(source, parentId, options) {
  let arr = [];
  if (source && source.length) {
    let opt = Object.assign({
      idField: "id",
      parentField: "parentId",
      childrenField: "children"
    }, options);
    for (let obj of source) {
      if (obj[opt.parentField] === parentId) {
        let n = Object.assign({}, obj);
        let subs = _build_tree(source, n[opt.idField], options);
        if (subs && subs.length) n[opt.childrenField] = subs;
        arr.push(n);
      }
    }
  }
  return arr;
}

function _focusPreventHandler(e) {
  e.stopImmediatePropagation();
}
function _addFocusPreventHandler() {
  _removeFocusPreventHandler();
  document.addEventListener("focus", _focusPreventHandler, true);
}
function _removeFocusPreventHandler() {
  document.removeEventListener("focus", _focusPreventHandler, true);
}

export default {
  install(Vue, args) {
    String.prototype.format = function (args) {
      let result = this;
      if (arguments.length > 0) {
        if (arguments.length === 1 && typeof (args) === 'object') {
          for (let key in args) {
            if (args[key] !== undefined) {
              let reg = new RegExp('({' + key + '})', 'g');
              result = result.replace(reg, args[key] === null ? '' : String(args[key]));
            }
          }
        } else {
          for (let i = 0; i < arguments.length; i++) {
            if (arguments[i] !== undefined) {
              let reg = new RegExp('({)' + i + '(})', 'g');
              result = result.replace(reg, arguments[i] === null ? '' : String(arguments[i]));
            }
          }
        }
      }
      return result;
    }

    String.prototype.getParameter = function (key) {
      let re = new RegExp(key + '=([^&]*)(?:&)?')
      return this.match(re) && this.match(re)[1]
    }

    Date.prototype.format = function (fmt = 'yyyy/MM/dd hh:mm:ss') {
      let o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        'S': this.getMilliseconds() // 毫秒
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
      }
      return fmt
    }

    Date.prototype.getWeek = function () {
      let beginDate = new Date(this.getFullYear(), 0, 1);
      return Math.ceil((parseInt((this - beginDate) / (24 * 60 * 60 * 1000)) + beginDate.getDay()) / 7);
    };

    if (!Array.prototype.find) {
      Array.prototype.find = function (fn) {
        var o = null;
        if (typeof fn === 'function') {
          for (var i = 0, ii = this.length; i < ii; i++) {
            var item = this[i];
            if (fn(item) === true) {
              o = item;
              break;
            }
          }
        }
        return o;
      };
    }

    if (!Array.prototype.findIndex) {
      Array.prototype.findIndex = function (fn) {
        var o = -1;
        if (typeof fn === 'function') {
          for (var i = 0, ii = this.length; i < ii; i++) {
            var item = this[i];
            if (fn(item) === true) {
              o = i;
              break;
            }
          }
        }
        return o;
      };
    }

    Vue.prototype.$random = function (min = 0, max = 100, ratio = 1) {
      let minValue = min * ratio;
      let maxValue = max * ratio;
      return (minValue + Math.round(Math.random() * (maxValue - minValue))) / ratio;
    };

    Vue.prototype.$Cookie = {
      set: function (name, value, num, interval) {
        /* eslint-disable no-new */
        let d = new Date()
        let base = 1000
        switch (interval) {
          case 'ms':
            base = 1
            break
          case 's':
            base = 1000
            break
          case 'm':
            base = 1000 * 60
            break
          case 'h':
            base = 1000 * 60 * 60
            break
          case 'd':
            base = 1000 * 60 * 60 * 24
            break;
        }

        interval || (interval = 's')
        num || (num = 1)
        d.setTime(d.getTime() + base * num)
        window.document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString()
      },
      get: function (name) {
        let v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
        return v ? v[2] : null
      },
      delete: function (name) {
        this.set(name, '', -1)
      }
    }

    Vue.prototype.$uuid = function (connectChar) {
      connectChar = connectChar == null ? '-' : connectChar;
      let d = new Date().getTime();
      let _uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
      });
      return connectChar === '-' ? _uuid : _uuid.replace(/-/ig, connectChar);
    }

    Vue.prototype.$isArray = function (o, strict = false) {
      let isarray = Object.prototype.toString.call(o) === '[object Array]'
      return isarray && (!strict || o.length > 0)
    }

    Vue.prototype.$isPlainObject = function (o, strict = true) {
      let isObj = typeof o === 'object' && Object.prototype.toString.call(o).toLowerCase() === '[object object]' && !o.length
      if (strict && isObj) {
        let empty = true
        for (let p in o) {
          empty = !p
          break
        }
        isObj = !empty
      }
      return isObj
    }

    Vue.prototype.$isDate = function (o, strict = true) {
      let isdate = o instanceof Date
      if (isdate && strict) {
        isdate = !isNaN(o.getTime())
      }
      return isdate
    }

    Vue.prototype.$buildTree = _build_tree;

    Vue.prototype.$goodsSpecConvert = function (a, b, c) {
      let specs = typeof a === "string" ? a : c;
      let str = [];
      try {
        specs = JSON.parse(specs || "{}");
        if (specs && specs.length) {
          specs.forEach(s => {
            if (s.name === '规格') {
              str.push(`${s.value}`);
            } else {
              str.push(`${s.name}：${s.value}`);
            }
          });
        }
      } catch (e) { }
      return str.join("；");
    }

    Vue.prototype.$routerLink = function (text, path, tooltip) {
      let able = false
      if (path && Vue.prototype.$isArray(Vue.prototype.$privilege) && Vue.prototype.$privilege.length > 0) {
        for (let p of Vue.prototype.$privilege) {
          let matchPath = p.path.replace(/(:(\w+))/i, (a, b, c) => {
            return p.param[c]
          })
          matchPath = '/' + matchPath
          if (matchPath === path) {
            able = true
          }
        }
      }

      let com = {}

      if (able) {
        com.template = tooltip ? `<Tooltip content="${tooltip}"><router-link :to="{path: '${path}'}">${text}</router-link></Tooltip>` : `<router-link :to="{path: '${path}'}">${text}</router-link>`
      } else {
        com.template = `<span>${text}</span>`
      }

      return com
    }

    Vue.prototype.$ScriptLinker = function (url, id) {
      return new Promise((resolve, reject) => {
        let header = document.getElementsByTagName('head')[0];
        let prevent = false;
        if (id) {
          let script = header.querySelector(`#${id}`);
          if (script) {
            prevent = true;
            resolve();
          }
        }
        if (!prevent) {
          if (url) {
            let script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = url
            if (id) script.id = id
            if (script.readyState) {
              // ie
              script.onreadystatechange = () => {
                if (script.readyState === 'loaded' || script.readyState === 'complete') {
                  script.onreadystatechange = null
                  resolve()
                }
              }
            } else {
              script.onload = () => {
                script.onload = null
                resolve()
              }
            }
            header.appendChild(script)
          } else {
            reject(new Error('URL params is required!'))
          }
        }
      })
    }

    Vue.prototype.$StyleSheetLinker = function (url) {
      return new Promise((resolve, reject) => {
        if (url) {
          let link = document.createElement('link')
          link.type = 'text/css'
          link.rel = 'stylesheet'
          link.href = url
          document.getElementsByTagName('head')[0].appendChild(link)
        } else {
          reject(new Error('URL params is required!'))
        }
      })
    }

    Vue.prototype.$checkNaN = function (data, key, defaultValue = 1, callback) {
      if (data) {
        if (!data.hasOwnProperty(key) || isNaN(data[key])) {
          this.$nextTick(_ => {
            data[key] = defaultValue;
            if (typeof callback === "function") callback(data);
          })
        } else {
          this.$nextTick(_ => {
            if (typeof callback === "function") callback(data);
          })
        }
      }
    }
    Vue.prototype.$addFocusPreventHandler = _addFocusPreventHandler;
    Vue.prototype.$removeFocusPreventHandler = _removeFocusPreventHandler;

    Vue.prototype.$erp = {
      enable: function (val) {
        if (typeof val === "boolean") {
          _erp_using = val;
        } else {
          return _erp_using;
        }
      }
    }

    Vue.prototype.$now = {
      get: function () {
        return _sys_now;
      },
      set: function (val) {
        if (_sys_now_timer) clearInterval(_sys_now_timer);
        _sys_now = val;
        _sys_now_timer = setInterval(_sys_now_update, 1000 * 60);
      },
      getDiffDays(days) {
        return _sys_now + (days * 24 * 60 * 60 * 1000);
      }
    }

    Vue.prototype.$entType = {
      get: function () {
        return _sys_ent_type;
      },
      set: function (val) {
        _sys_ent_type = val;
      }
    }

    Vue.prototype.$chn = function (money) {
      //汉字的数字
      var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
      //基本单位
      var cnIntRadice = new Array('', '拾', '佰', '仟');
      //对应整数部分扩展单位
      var cnIntUnits = new Array('', '万', '亿', '兆');
      //对应小数部分单位
      var cnDecUnits = new Array('角', '分', '毫', '厘');
      //整数金额时后面跟的字符
      var cnInteger = '整';
      //整型完以后的单位
      var cnIntLast = '元';
      //最大处理的数字
      var maxNum = 999999999999999.9999;
      //金额整数部分
      var integerNum;
      //金额小数部分
      var decimalNum;
      //输出的中文金额字符串
      var chineseStr = '';
      //分离金额后用的数组，预定义
      var parts;
      if (money === '') {
        return '';
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return '';
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = '';
      } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == '0') {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != '') {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == '') {
        chineseStr += cnInteger;
      }
      return chineseStr;
    }

    Vue.prototype.$price = function (a, b = 2, c, d, e = "￥") {
      let v = c;
      if (typeof a === "number") v = a;
      if (typeof b !== "number") b = 2;
      if (typeof v !== "number" || isNaN(v)) return "-";
      let vstr = ((v || 0) / 100).toFixed(b);
      let vs = vstr.split(".");
      if (b > 2) {
        vs[1] = vs[1].replace(/0+$/ig, "");
        if (vs[1].length < 2) vs[1] = `${vs[1]}00`.substr(0, 2);
      }
      return e + vs[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + "." + vs[1];
    }

    Vue.prototype.$quantity = function (a, decimal = 0) {
      // return a;
      let v = null;
      if (typeof a === "number" && !isNaN(a)) v = a;
      else return "-";

      let times = decimal > 0 ? (10 ^ decimal) : 1;
      v = Math.round(v * times) / times;
      let vstr = v.toFixed(decimal);
      let vs = vstr.split(".");
      return vs[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + (vs.length > 1 ? ("." + vs[1]) : "");
    }

    Vue.prototype.$percent = function (v) {
      if (typeof v !== "number" || isNaN(v)) return "-";
      else return Math.round(v * 10000) / 100 + "%";
    }

    Vue.prototype.$print = function (type, id, params) {
      let url = "/print/" + btoa(type + "/" + id);
      if (params && Vue.prototype.$isPlainObject(params, true)) {
        url = url + "?" + qs.stringify(params, {
          arrayFormat: "repeat"
        })
      }
      window.open(url, "print", "location=no,status=no,toolbar=no,menubar=no,width=1200,height=600");
    }

    Vue.prototype.$Install = function (io, name) {
      if (io) {
        if (name) {
          Vue.use(io, {
            name: name
          })
        } else {
          Vue.use(io)
        }
      }
    }

    Vue.prototype.$SHA265 = function (s) {
      let chrsz = 8;
      let hexcase = 0;

      function safeAdd(x, y) {
        let lsw = (x & 0xFFFF) + (y & 0xFFFF);
        let msw = (x >> 16) + (y >> 16) + (lsw >> 16);
        return (msw << 16) | (lsw & 0xFFFF);
      }

      function S(X, n) {
        return (X >>> n) | (X << (32 - n));
      }

      function R(X, n) {
        return (X >>> n);
      }

      function Ch(x, y, z) {
        return ((x & y) ^ ((~x) & z));
      }

      function Maj(x, y, z) {
        return ((x & y) ^ (x & z) ^ (y & z));
      }

      function Sigma0256(x) {
        return (S(x, 2) ^ S(x, 13) ^ S(x, 22));
      }

      function Sigma1256(x) {
        return (S(x, 6) ^ S(x, 11) ^ S(x, 25));
      }

      function Gamma0256(x) {
        return (S(x, 7) ^ S(x, 18) ^ R(x, 3));
      }

      function Gamma1256(x) {
        return (S(x, 17) ^ S(x, 19) ^ R(x, 10));
      }

      function coreSha256(m, l) {
        let K = [0x428A2F98, 0x71374491, 0xB5C0FBCF, 0xE9B5DBA5, 0x3956C25B, 0x59F111F1, 0x923F82A4, 0xAB1C5ED5, 0xD807AA98, 0x12835B01, 0x243185BE, 0x550C7DC3, 0x72BE5D74, 0x80DEB1FE, 0x9BDC06A7, 0xC19BF174, 0xE49B69C1, 0xEFBE4786, 0xFC19DC6, 0x240CA1CC, 0x2DE92C6F, 0x4A7484AA, 0x5CB0A9DC, 0x76F988DA, 0x983E5152, 0xA831C66D, 0xB00327C8, 0xBF597FC7, 0xC6E00BF3, 0xD5A79147, 0x6CA6351, 0x14292967, 0x27B70A85, 0x2E1B2138, 0x4D2C6DFC, 0x53380D13, 0x650A7354, 0x766A0ABB, 0x81C2C92E, 0x92722C85, 0xA2BFE8A1, 0xA81A664B, 0xC24B8B70, 0xC76C51A3, 0xD192E819, 0xD6990624, 0xF40E3585, 0x106AA070, 0x19A4C116, 0x1E376C08, 0x2748774C, 0x34B0BCB5, 0x391C0CB3, 0x4ED8AA4A, 0x5B9CCA4F, 0x682E6FF3, 0x748F82EE, 0x78A5636F, 0x84C87814, 0x8CC70208, 0x90BEFFFA, 0xA4506CEB, 0xBEF9A3F7, 0xC67178F2];
        let HASH = [0x6A09E667, 0xBB67AE85, 0x3C6EF372, 0xA54FF53A, 0x510E527F, 0x9B05688C, 0x1F83D9AB, 0x5BE0CD19];
        /* eslint-disable no-new */
        let W = new Array(64);
        let a, b, c, d, e, f, g, h, i, j;
        let T1, T2;
        m[l >> 5] |= 0x80 << (24 - l % 32);
        m[((l + 64 >> 9) << 4) + 15] = l;
        for (i = 0; i < m.length; i += 16) {
          a = HASH[0];
          b = HASH[1];
          c = HASH[2];
          d = HASH[3];
          e = HASH[4];
          f = HASH[5];
          g = HASH[6];
          h = HASH[7];
          for (j = 0; j < 64; j++) {
            if (j < 16) W[j] = m[j + i];
            else W[j] = safeAdd(safeAdd(safeAdd(Gamma1256(W[j - 2]), W[j - 7]), Gamma0256(W[j - 15])), W[j - 16]);
            T1 = safeAdd(safeAdd(safeAdd(safeAdd(h, Sigma1256(e)), Ch(e, f, g)), K[j]), W[j]);
            T2 = safeAdd(Sigma0256(a), Maj(a, b, c));
            h = g;
            g = f;
            f = e;
            e = safeAdd(d, T1);
            d = c;
            c = b;
            b = a;
            a = safeAdd(T1, T2);
          }
          HASH[0] = safeAdd(a, HASH[0]);
          HASH[1] = safeAdd(b, HASH[1]);
          HASH[2] = safeAdd(c, HASH[2]);
          HASH[3] = safeAdd(d, HASH[3]);
          HASH[4] = safeAdd(e, HASH[4]);
          HASH[5] = safeAdd(f, HASH[5]);
          HASH[6] = safeAdd(g, HASH[6]);
          HASH[7] = safeAdd(h, HASH[7]);
        }
        return HASH;
      }

      function str2binb(str) {
        let bin = [];
        let mask = (1 << chrsz) - 1;
        for (let i = 0; i < str.length * chrsz; i += chrsz) {
          bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - i % 32);
        }
        return bin;
      }

      function Utf8Encode(string) {
        string = string.replace(/\r\n/g, '\n');
        let utftext = '';
        for (let n = 0; n < string.length; n++) {
          let c = string.charCodeAt(n);
          if (c < 128) {
            utftext += String.fromCharCode(c);
          } else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
          } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
          }
        }
        return utftext;
      }

      function binb2hex(binarray) {
        let hexTab = hexcase ? '0123456789ABCDEF' : '0123456789abcdef';
        let str = '';
        for (let i = 0; i < binarray.length * 4; i++) {
          str += hexTab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 0xF) +
            hexTab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8)) & 0xF);
        }
        return str;
      }

      s = Utf8Encode(s);
      return binb2hex(coreSha256(str2binb(s), s.length * chrsz));
    }

    function _emoji_convert_unit(str) {
      var patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
      str = str.replace(patt, function (char) {
        var H, L, code;
        if (char.length === 2) {
          H = char.charCodeAt(0); // 取出高位
          L = char.charCodeAt(1); // 取出低位
          code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法
          return "&#" + code + ";";
        } else {
          return char;
        }
      });
      return str;
    }

    function _emoji_converter(d) {
      if (Vue.prototype.$isPlainObject(d)) {
        for (let k in d) {
          if (d.hasOwnProperty(k)) {
            let sd = d[k];
            if (typeof sd === 'string' && sd !== '') {
              d[k] = _emoji_convert_unit(sd);
            } else {
              _emoji_converter(sd);
            }
          }
        }
      }
    }

    Vue.prototype.$emojiConvert = function (d) {
      _emoji_converter(d);
    }
  }
}