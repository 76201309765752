<template>
  <div id="print" :class="type">
    <component :is="paper" :id="id" v-bind="params" @loaded="handleLoaded" @error="handleError" v-if="paper" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      type: null,
      params: null,
      paper: null,
    };
  },
  methods: {
    navigate() {
      try {
        let ps = atob(this.$route.params.char).split("/");
        let type = ps[0],
          id = ps[1];
        if (type && id) {
          this.type = type;
          this.id = id;
          this.params = this.$route.query;
          require([`@/views/print/assembly/${type}`], (comp) => {
            this.paper = comp.default;
          });
        }
      } catch (e) {}
    },
    handleLoaded() {
      window.print();
    },
    handleError(e) {},
  },
  mounted() {
    this.navigate();
  },
};
</script>

<style lang="less">
#print {
  width: 21cm;
  min-height: 24cm;
  padding: 2.5cm 1.9cm;
  box-sizing: content-box;
  font-size: 10pt;

  &.retail {
    padding: 1cm;
  }

  .title {
    font-size: 22pt;
    letter-spacing: 6pt;
    font-weight: bold;
    font-family: "华文中宋", "Times New Roman", Times, serif;
    padding-bottom: 10pt;
  }

  div {
    line-height: 1.5;
  }

  table {
    width: 100%;
    border: black solid;
    border-width: 0 0 1px 1px;
    margin: 6pt 0;
    table-layout: fixed;

    td {
      padding: 5pt;
      border: black solid;
      border-width: 1px 1px 0 0;

      &.inner {
        padding: 0;
        border: none;
        > table {
          margin: 0 !important;
          border: none;
        }
      }
    }
    
    &.no-border {
      border: none;
      td {
        border: none;
        padding: 0.2pt 0.5pt;
      }
    }
  }
}
@media print {
  #print {
    padding: 0;
  }
}
</style>