<template>
  <el-dialog :visible.sync="visible" title="切换账号" append-to-body :close-on-click-modal="false" width="560px" @closed="handleClosed">
    <div style="margin-bottom: 12px;">
      <!-- <quick-select v-model="query.enterpriseId" url="api/distributor"  placeholder="经销商" clearable filterable auto-select-first-option @change="toQuery" value-field="enterpriseId" style="width: 100%;" /> -->
      <!-- <quick-select
        class="filter-item"
        v-model="query.enterpriseId"
        filterable
        remote
        :page-size="50"
        value-field="enterpriseId"
        url="api/distributor"
        :params="{'enabled':true}"
        style="width: 100%;"
        :filterMethod="(s, d) => {
                return s.filter(o => {
                  return (o.erpId || '').toLowerCase().indexOf(d) >= 0 || (o.name || '').toLowerCase().indexOf(d) >= 0;
                });
              }"
        placeholder="经销商"
        @change="toQuery"
        clearable
        auto-select-first-option
      >
        <template slot-scope="scope">（{{scope.row.erpId}}）{{scope.row.name}}</template>
      </quick-select>-->

      <el-select v-model="query.enterpriseId" placeholder="请选择经销商" clearable remote filterable :remote-method="loadSuppliers" @change="toQuery" style="width: 100%;">
        <el-option v-for="s in suppliers" :key="s.id" :value="s.enterpriseId" :label="s.name">{{s.name}}（{{s.erpId}}）</el-option>
      </el-select>
    </div>
    <el-input v-model="query.blurry" clearable placeholder="输入名称或者账号搜索" style="width: 240px;" @clear="toQuery" @keyup.enter.native="toQuery">
      <el-button slot="append" icon="el-icon-search" @click="toQuery"></el-button>
    </el-input>
    <el-table ref="list" v-loading="loading" :data="data" row-key="id" highlight-current-row max-height="480px" @current-change="handleSelectChange">
      <el-table-column prop="username" label="账号" min-width="120" />
      <el-table-column prop="userRealName" label="姓名" min-width="120" />
      <el-table-column label="角色" :formatter="r=>{return showRoles(r.roles)}" min-width="250" />
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" :page-size="size" style="margin-top: 8px;" layout="total, prev, pager, next" @current-change="pageChange" />
    <div slot="footer">
      <el-button type="primary" :disabled="!current" :loading="switching" @click="doSwitch">确定</el-button>
      <el-button @click="visible = false">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import initData from "@/mixins/initData";
import request from "@/utils/request";

export default {
  mixins: [initData],
  data() {
    return {
      visible: false,
      current: null,
      switching: false,
      suppliers: [],
      query: {
        blurry: "",
        enterpriseId: null,
        enabled: true,
        sort: "id,desc",
      },
    };
  },
  methods: {
    beforeInit() {
      if (this.query.enterpriseId) {
        this.url = "api/users";
        this.params = this.query;
        this.size = 10;
        return true;
      } else {
        this.data = [];
        this.total = 0;
        return false;
      }
    },
    handleClosed() {
      this.current = null;
      this.$refs.list && this.$refs.list.setCurrentRow();
    },
    handleSelectChange(row) {
      this.current = row;
    },
    show() {
      this.visible = true;
    },
    showRoles(data) {
      let n = "";
      if (data.length > 1) {
        data.forEach((o) => {
          n += o.name + ",";
        });
      } else if (data.length == 1) {
        data.forEach((o) => {
          n = o.name;
        });
      }
      return n;
    },
    doSwitch() {
      if (this.current) {
        this.switching = true;
        this.$store
          .dispatch("SwitchLogin", this.current)
          .then(() => {
            location.href = "/";
          })
          .finally((_) => {
            this.switching = false;
          });
      }
    },
    loadSuppliers(kw) {
      request({
        url: "api/distributor",
        method: "get",
        params: {
          page: 0,
          size: 50,
          nameOrErpId: kw,
        },
      })
        .then((res) => {
          this.suppliers = res.content;
        })
        .catch((err) => {
          this.suppliers = [];
        });
    },
  },
  mounted() {
    this.loadSuppliers();
  },
};
</script>