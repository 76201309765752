<template>
  <div :class="classObj" class="app-wrapper" v-if="getNow">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div :class="{hasTagsView:needTagsView}" class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar />
        <tags-view v-if="needTagsView" />
      </div>
      <app-main />
      <right-panel>
        <settings />
      </right-panel>
    </div>

    <div class="watermask" :style="{backgroundImage: watermask}"></div>
  </div>
</template>

<script>
import RightPanel from "@/components/RightPanel";
import { AppMain, Navbar, Settings, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState, mapGetters } from "vuex";
import request from "@/utils/request";

export default {
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    Settings,
    Sidebar,
    TagsView,
    RightPanel,
  },
  data() {
    return {
      getNow: false,
    };
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapGetters(["user"]),
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader,
    }),
    watermask() {
      if (this.user && this.user.username) {
        let un = this.user.username.toUpperCase();
        // un = "相同的";
        let len = un.length * 2000;
        // len = 6000;
        let w = len * 3;
        if (w < 20000) w = 20000;
        let svg = `<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="${w / 100}px" height="160px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 ${w} 15000"
 xmlns:xlink="http://www.w3.org/1999/xlink"><defs>
<style type="text/css">
<![CDATA[
    .fil0 {fill:rgba(0,0,0,.02);}
    .fnt0 {font-weight:normal;font-size:4000px;}
   ]]>
  </style>
 </defs>
 <g id="Layer_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <text x="-${len / 2}" y="4000"  class="fil0 fnt0">${un}</text>
  <text x="${w - len / 2}" y="4000"  class="fil0 fnt0">${un}</text>
  <text x="${w / 2 - len / 2}" y="12000"  class="fil0 fnt0">${un}</text>
 </g>
</svg>`;
        return `url(data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svg)))})`;
      }
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("closeSideBar", { withoutAnimation: false });
    },
  },
  mounted() {
    request({
      url: "api/check/erp",
      method: "get",
    }).then((res) => {
      this.$erp.enable(res === true);
    });

    request({
      url: "api/current/time",
      method: "get",
    }).then((res) => {
      this.getNow = true;
      this.$now.set(res || new Date().getTime());
    });

    request({
      url: "api/capitalPool/type",
      method: "get",
    }).then((res) => {
      console.log(this.user)
      if (String(this.user.entId) === "18363") {
        res = "kingkoil";
      }
      this.$entType.set(res);
    });
  },
};
</script>

<style lang="scss">
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

// 主体区域
.main-container {
  // min-height: 100%;
  margin-left: 200px;
  position: relative;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  font-size: 14px;
}

// 侧边栏
.sidebar-container {
  width: 200px;
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
}

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  // position: fixed;
  // top: 0;
  // right: 0;
  // background: #ffffff;
  position: relative;
  z-index: 99;
  // width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - #{$hideSidebarWidth});
}

.mobile .fixed-header {
  width: 100%;
}

.watermask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999999;
  pointer-events: none;
}
</style>

