<template>
  <div class="el-select" :class="[selectSize ? 'el-select--' + selectSize : '']" v-clickoutside="handleClose" v-if="picker">
    <div class="el-select__tags" v-if="multiple" @click.stop="toggleMenu" ref="tags" :style="{ 'max-width': inputWidth - 32 + 'px' }" v-show="!reference">
      <span v-if="collapseTags && selection.length">
        <el-tag :closable="!disabled" :size="collapseTagSize" type="info" @close="deleteTag($event, selection[0])" disable-transitions>
          <span class="el-select__tags-text">{{ calcDisplayText(selection[0]) }}</span>
        </el-tag>
        <el-tag v-if="selection.length > 1" :closable="false" :size="collapseTagSize" type="info" disable-transitions>
          <span class="el-select__tags-text">+ {{ selection.length - 1 }}</span>
        </el-tag>
      </span>
      <transition-group @after-leave="resetInputHeight" v-if="!collapseTags">
        <el-tag v-for="item in selection" :key="item[valueField]" :closable="!disabled" size="small" type="info" @close="deleteTag($event, item)" disable-transitions>
          <span class="el-select__tags-text">{{ calcDisplayText(item) }}</span>
        </el-tag>
      </transition-group>
    </div>
    <el-input
      ref="reference"
      :value="calcDisplayText(selected)"
      type="text"
      :placeholder="currentPlaceholder"
      :name="name"
      :id="id"
      v-if="!reference"
      :size="selectSize"
      :disabled="disabled"
      :readonly="true"
      :validate-event="false"
      :class="{ 'is-focus': visible }"
      @focus="handleFocus"
      @blur="handleBlur"
      @mousedown.native="handleMouseDown"
      @mouseenter.native="inputHovering = true"
      @mouseleave.native="inputHovering = false"
    >
      <i slot="suffix" :class="['el-select__caret', 'el-input__icon', 'el-icon-' + iconClass]" @click="handleIconClick"></i>
    </el-input>
    <el-button ref="reference" :type="referenceType" :size="referenceSize" :disabled="disabled" v-else @click="handleIconClick">
      <i :class="referenceIcon" v-if="referenceIcon"></i>
      {{referenceText}}
    </el-button>

    <el-dialog :visible.sync="visible" append-to-body :close-on-click-modal="false" :title="dialogTitle" :width="dialogWidth" v-if="dialog">
      <slot ref="form" name="banner" :query="handleDoQuery" />
      <el-table ref="table" :size="selectSize" v-loading="loading" :highlight-current-row="!multiple" border @current-change="handleSelectChange" @selection-change="handleSelectionChange" :data="store" :row-key="valueField" :empty-text="emptyText">
        <el-table-column type="index" v-if="showIndex" width="50" />
        <el-table-column v-if="multiple" type="selection" width="35" reserve-selection :selectable="checkSelectable"></el-table-column>
        <slot></slot>
      </el-table>
      <el-pagination v-if="pagable && $isArray(store, true)" :total="total" :page-size.sync="pageSize" :current-page="page" :layout="pageLayout" popper-class="datagrid-picker-pagination-popper" @current-change="handlePageChange" @size-change="handlePageSizeChange" style="margin-top: 8px;"></el-pagination>
    </el-dialog>

    <template v-else>
      <transition name="el-zoom-in-top" @after-leave="doDestroy">
        <el-select-menu ref="popper" class="datagrid-picker-popper" v-show="visible">
          <div class="app-el-poper-scrollbar" :style="{width: popperWidth && popperWidth > 0 ? `${popperWidth}px` : null}">
            <slot ref="form" name="banner" :query="handleDoQuery" />
            <el-table height="320" ref="table" :size="selectSize" v-loading="loading" :highlight-current-row="!multiple" @current-change="handleSelectChange" @selection-change="handleSelectionChange" :data="store" :row-key="valueField" :empty-text="emptyText">
              <el-table-column type="index" v-if="showIndex" width="50" />
              <el-table-column v-if="multiple" type="selection" width="35" reserve-selection :selectable="checkSelectable"></el-table-column>
              <slot></slot>
            </el-table>
            <el-pagination v-if="pagable && $isArray(store, true)" :total="total" :page-size.sync="pageSize" :current-page="page" :layout="pageLayout" popper-class="datagrid-picker-pagination-popper" @current-change="handlePageChange" @size-change="handlePageSizeChange"></el-pagination>
            <div style="padding: 1rem" v-if="multiple && reference && submit">
              <el-button type="primary" @click.stop="handleSubmit">确定</el-button>
            </div>
          </div>
        </el-select-menu>
      </transition>
    </template>
  </div>

  <div v-else>
    <slot ref="form" name="banner" :query="handleDoQuery" />
    <el-table height="320" ref="table" :size="selectSize" v-loading="loading" :highlight-current-row="!multiple" @current-change="handleSelectChange" @selection-change="handleSelectionChange" :data="store" :row-key="valueField" :empty-text="emptyText">
      <el-table-column type="index" v-if="showIndex" align="center" :index="inx => { return (page - 1) * pageSize + inx + 1; }" width="50" />
      <el-table-column v-if="multiple" type="selection" width="35" :selectable="checkSelectable"></el-table-column>
      <slot></slot>
    </el-table>
    <el-pagination v-if="pagable && $isArray(store, true)" :total="total" :page-size.sync="pageSize" :current-page="page" :layout="pageLayout" popper-class="datagrid-picker-pagination-popper" @current-change="handlePageChange" @size-change="handlePageSizeChange"></el-pagination>
    <div style="padding: 1rem" v-if="multiple && reference && submit">
      <el-button type="primary" @click.stop="handleSubmit">确定</el-button>
    </div>
  </div>
</template>

<script>
import { t } from "element-ui/src/locale";
import Clickoutside from "element-ui/src/utils/clickoutside";
import {
  addResizeListener,
  removeResizeListener,
} from "element-ui/src/utils/resize-event";
import { addClass, removeClass, hasClass } from "element-ui/src/utils/dom";
import Emitter from "element-ui/src/mixins/emitter";
import Focus from "element-ui/src/mixins/focus";
import Locale from "element-ui/src/mixins/locale";
import { valueEquals } from "element-ui/src/utils/util";
import ElSelectMenu from "element-ui/packages/select/src/select-dropdown.vue";
import request from "@/utils/request";

export default {
  name: "CDatagridPicker",
  componentName: "CDatagridPicker",
  mixins: [Emitter, Locale, Focus("reference")],
  directives: { Clickoutside },
  components: { ElSelectMenu },
  props: {
    name: String,
    id: String,
    value: String | Number | Object,
    size: { type: String, default: "small" },
    loadingText: String,
    noMatchText: String,
    noDataText: String,
    picker: {
      type: Boolean,
      default: true,
    },
    dialog: Boolean,
    dialogTitle: String,
    dialogWidth: String,
    showIndex: {
      type: Boolean,
      default: true,
    },
    formatter: Function,
    multiple: Boolean,
    multipleLimit: {
      type: Number,
      default: 0,
    },
    excludeKeys: Array,
    reference: String | Boolean,
    referenceType: String,
    referenceIcon: String,
    referenceSize: String,
    submit: Boolean,
    placeholder: {
      type: String,
      default() {
        return t("el.select.placeholder");
      },
    },
    valueField: {
      type: String,
      default: "id",
    },
    displayField: {
      type: String,
      default: "name",
    },
    queryValueField: {
      type: String,
      default: null,
    },
    params: Object,
    queryDefine: Object,
    pageField: {
      type: String,
      default: "page",
    },
    pageSizeField: {
      type: String,
      default: "size",
    },
    url: String,
    displayUrl: String,
    emptyText: String,
    pageSize: {
      type: Number,
      default: 10,
      validator: (val) => {
        return val > 0 && val < 100;
      },
    },
    pageLayout: {
      type: String,
      default: "prev, pager, next, ->, total",
    },
    disabled: Boolean,
    clearable: Boolean,
    pagable: {
      type: Boolean,
      default: true,
    },
    simpleValue: {
      type: Boolean,
      default: true,
    },
    collapseTags: Boolean,
    popperWidth: Number,
  },
  data() {
    return {
      initing: false,
      inited: false,
      dialogInited: false,
      loading: false,
      visible: false,
      inputWidth: 0,
      currentPlaceholder: "",
      cachedPlaceHolder: "",
      inputHovering: false,
      initialInputHeight: 0,
      total: 0,
      store: [],
      originParams: this.params,
      // ? JSON.parse(JSON.stringify(this.params))
      // : null,
      currentParams: this.params,
      // ? JSON.parse(JSON.stringify(this.params))
      // : null,
      attachSelectionChange: true,
      selection: null,
      selected: null,
      page: 1,
      querying: false,
      canceller: null,
    };
  },
  inject: {
    elFormItem: {
      default: "",
    },
  },
  provide() {
    return {
      select: this,
    };
  },
  computed: {
    _elFormItemSize() {
      return (this.elFormItem || {}).elFormItemSize;
    },
    referenceText() {
      if (typeof this.reference === "string") return this.reference;
      else return "";
    },
    iconClass() {
      if (this.initing) return "loading";
      let criteria =
        this.clearable &&
        !this.disabled &&
        this.inputHovering &&
        !this.multiple &&
        this.value !== undefined &&
        this.value !== "";
      return criteria ? "circle-close is-show-close" : "arrow-up";
    },
    selectSize() {
      return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
    collapseTagSize() {
      return ["small", "mini"].indexOf(this.selectSize) > -1 ? "mini" : "small";
    },
  },
  methods: {
    calcDisplayText(item) {
      if (item) {
        if (this.formatter && typeof this.formatter === "function") {
          return this.formatter(item);
        } else {
          return item[this.displayField];
        }
      } else {
        return "";
      }
    },
    handleDoQuery() {
      if (this.$isPlainObject(this.queryDefine)) {
        let params = Object.assign(
          JSON.parse(JSON.stringify(this.queryDefine || {})),
          JSON.parse(JSON.stringify(this.originParams || {}))
        );
        this.currentParams = params;
        this.querying = true;
      }
      this.reload();
    },
    toggleMenu() {
      if (!this.disabled) {
        this.visible = !this.visible;
        if (this.visible) {
          (this.$refs.input || this.$refs.reference).focus &&
            (this.$refs.input || this.$refs.reference).focus();
        }
      }
    },
    doDestroy() {
      this.$refs.popper && this.$refs.popper.doDestroy();
    },
    handleClose() {
      if (this.dialog) return;
      this.visible = false;
      if (this.$refs.reference) {
        this.$refs.reference.$refs.input &&
          this.$refs.reference.$refs.input.blur();
      }
    },
    handleFocus(event) {
      this.visible = true;
      this.$emit("focus", event);
    },
    handleBlur(event) {
      this.$emit("blur", event);
    },

    handleIconHide() {
      let icon = this.$el.querySelector(".el-input__icon");
      if (icon) {
        removeClass(icon, "is-reverse");
      }
    },

    handleIconShow() {
      let icon = this.$el.querySelector(".el-input__icon");
      if (icon && !hasClass(icon, "el-icon-circle-close")) {
        addClass(icon, "is-reverse");
      }
    },
    handleMouseDown(event) {
      if (event.target.tagName !== "INPUT") return;
      if (this.visible) {
        this.handleClose();
        event.preventDefault();
      }
    },
    handleResize() {
      this.resetInputWidth();
      if (this.multiple) this.resetInputHeight();
      this.broadcast("ElSelectDropdown", "updatePopper");
    },
    handleIconClick(event) {
      if (this.iconClass.indexOf("circle-close") > -1) {
        this.deleteSelected(event);
      } else {
        this.toggleMenu();
      }
    },
    handlePageChange(p) {
      if (p > 0) {
        this.page = p;
        this.load();
      }
    },
    handlePageSizeChange() {
      this.reload();
      this.$nextTick(() => {
        this.visible = true;
      });
    },
    resetInputWidth() {
      this.inputWidth = this.$refs.reference.$el.getBoundingClientRect().width;
    },

    resetInputHeight() {
      if (this.collapseTags && !this.filterable) return;
      this.$nextTick(() => {
        if (!this.$refs.reference) return;
        let inputChildNodes = this.$refs.reference.$el.childNodes;
        let input = [].filter.call(
          inputChildNodes,
          (item) => item.tagName === "INPUT"
        )[0];
        const tags = this.$refs.tags;
        const sizeInMap = this.initialInputHeight || 40;
        input.style.height =
          !this.selection || this.selection.length === 0
            ? sizeInMap + "px"
            : Math.max(
                tags
                  ? tags.clientHeight + (tags.clientHeight > sizeInMap ? 6 : 0)
                  : 0,
                sizeInMap
              ) + "px";
        if (this.visible && this.emptyText !== false) {
          this.broadcast("ElSelectDropdown", "updatePopper");
        }
      });
    },
    handleSelectChange(v) {
      if (!this.multiple && !!v) {
        this.selected = v;
        this.updateValue();
      }
    },
    handleSelectionChange(v) {
      if (this.multiple && this.attachSelectionChange) {
        this.selection = v;
        this.updateValue();
      }
    },
    updateValue(isInit = false) {
      if (this.multiple && !!this.reference && this.submit) return;
      let value = null;
      if (this.multiple) {
        value = this.selection;
        if (this.simpleValue && this.$isArray(this.selection, true)) {
          value = this.selection.map((s) => {
            return s[this.valueField];
          });
        }
      } else {
        value = this.selected;
        if (this.simpleValue && this.$isPlainObject(this.selected)) {
          value = this.selected[this.valueField];
        }
      }

      if (!valueEquals(this.value, value)) {
        this.$emit("input", value);
        this.dispatch("ElFormItem", "el.form.change", value);
      }
      if (this.multiple) {
        this.$emit(
          "selection-change",
          value,
          this.value,
          this.selection,
          isInit
        );
      } else {
        this.$emit("change", value, this.value, this.selected, isInit);
        //this.selected = null;
        this.$refs.table.clearSelection();
        this.$refs.table.setCurrentRow();
        this.visible = false;
      }
    },
    handleSubmit() {
      if (this.multiple && this.reference && this.submit) {
        this.$emit("submit", this.selection);
        this.visible = false;
      }
    },
    deleteTag(event, tag) {
      if (!this.disabled) {
        this.$refs.table.toggleRowSelection(tag, false);
        this.$emit("remove-tag", tag);
      }
      event.stopPropagation();
    },

    deleteSelected(event) {
      event.stopPropagation();
      this.selected = null;
      this.$refs.table.clearSelection();
      this.$refs.table.setCurrentRow();
      this.visible = false;
      this.updateValue();
      this.$emit("clear");
    },
    setSelectStaus(selection) {
      if (this.dialog && !this.dialogInited) {
        this.selection = selection;
      } else {
        if (this.multiple) {
          if (this.$isArray(selection) && selection.length) {
            this.attachSelectionChange = false;
            let table = this.$refs.table;
            for (let i in selection) {
              if (i == selection.length - 1) {
                this.attachSelectionChange = true;
              }
              let exist = this.store.find((o) => {
                return o[this.valueField] === selection[i][this.valueField];
              });
              if (!exist) exist = selection[i];
              table && table.toggleRowSelection(exist, true);
            }
          }
        } else if (selection && selection.hasOwnProperty(this.valueField)) {
          this.attachSelectionChange = false;
          this.$refs.table &&
            this.$refs.table.setCurrentRow(selection[this.valueField]);
        }
      }
    },
    setSelection(value) {
      if (!this.displayUrl && !this.url) return;
      let vs = [];
      if (!this.multiple) {
        if (
          (this.simpleValue && value != null && value !== "") ||
          (!this.simpleValue && this.$isPlainObject(value))
        ) {
          vs.push(this.simpleValue ? value : value[this.valueField]);
        }
      } else if (this.$isArray(value, true)) {
        value.forEach((o) => {
          vs.push(this.simpleValue ? o : o[this.valueField]);
        });
      }

      if (vs.length > 0) {
        this.initing = true;
        let o = {
          page: 0,
          size: vs.length,
        };
        if (this.multiple) {
          o[this.queryValueField || this.valueField] = vs;
        } else {
          o[this.queryValueField || this.valueField] = vs[0];
        }
        request({
          url: this.displayUrl || this.url,
          method: "get",
          params: o,
        })
          .then((res) => {
            if (this.$isArray(res.content)) {
              if (res.content.length > 0) {
                if (this.multiple) {
                  this.setSelectStaus(res.content);
                  this.updateValue(true);
                  this.resetInputHeight();
                } else {
                  this.selected = res.content[0];
                  this.updateValue(true);
                }
              }
            } else if (res) {
              if (this.multiple) {
                this.setSelectStaus(res);
                this.updateValue(true);
                this.resetInputHeight();
              } else if (this.$isArray(res, true)) {
                this.selected = res;
                this.updateValue(true);
              }
            }
          })
          .finally(() => {
            this.initing = false;
          });
      }
    },
    checkSelectable(row) {
      let enable = true;
      if (this.excludeKeys && this.excludeKeys.length) {
        enable =
          this.excludeKeys.findIndex((ek) => {
            if (this.$isPlainObject(ek)) {
              return row[this.valueField] === ek[this.valueField];
            } else {
              return row[this.valueField] === ek;
            }
          }) < 0;
      }
      if (
        enable &&
        this.multipleLimit > 0 &&
        this.selection.length >= this.multipleLimit
      ) {
        enable =
          this.selection.findIndex((s) => {
            return s[this.valueField] === row[this.valueField];
          }) >= 0;
      }
      return enable;
    },
    managePlaceholder() {
      if (this.currentPlaceholder !== "") {
        this.currentPlaceholder = this.$refs.input.value
          ? ""
          : this.cachedPlaceHolder;
      }
    },
    reload() {
      this.page = 1;
      this.load();
    },
    load() {
      if (this.url) {
        this.loading = true;
        this.inited = true;
        let o = Object.assign({}, this.currentParams);
        o[this.pageField] = this.page - 1;
        o[this.pageSizeField] = this.pageSize;
        request({
          url: this.url,
          method: "get",
          params: o,
        })
          .then((res) => {
            this.total = res.totalElements;
            this.store = res.content;
            this.$nextTick(() => {
              this.querying = false;
              if (this.dialog && !this.dialogInited) {
                this.dialogInited = true;
                this.setSelectStaus([].concat(this.selection || []));
              }
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  created() {
    this.cachedPlaceHolder = this.currentPlaceholder = this.placeholder;
  },
  mounted() {
    if (this.multiple && Array.isArray(this.value) && this.value.length > 0) {
      this.currentPlaceholder = "";
    }
    const reference = this.$refs.reference;
    if (reference && reference.$el) {
      const sizeMap = {
        medium: 36,
        small: 32,
        mini: 28,
      };
      const input = reference.$el.querySelector("input");
      if (input) {
        this.initialInputHeight =
          input.getBoundingClientRect().height || sizeMap[this.selectSize];
      }
    }
    if (this.remote && this.multiple) {
      this.resetInputHeight();
    }
    if (!this.picker) {
      this.load();
    }
    addResizeListener(this.$el, this.handleResize);
    this.setSelection(this.value);
  },
  watch: {
    visible(val) {
      if (val) {
        this.handleIconShow();
        this.broadcast("ElSelectDropdown", "updatePopper");
        if (!this.inited) {
          this.load();
        } else if (this.multiple && this.reference && this.submit) {
          this.$refs.table && this.$refs.table.clearSelection();
        }
      } else {
        this.handleIconHide();
        this.broadcast("ElSelectDropdown", "destroyPopper");
      }
    },
    placeholder(val) {
      this.cachedPlaceHolder = this.currentPlaceholder = val;
    },
    url(newValue, oldValue) {
      if (newValue !== oldValue) {
        let ov = this.value;
        this.selected = null;
        this.$refs.table && this.$refs.table.clearSelection();
        this.handleDoQuery();
        this.setSelection(ov);
      }
    },
    params: {
      handler: function (newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.originParams = JSON.parse(JSON.stringify(newValue));
          this.currentParams = JSON.parse(JSON.stringify(newValue));
          this.$nextTick(() => {
            this.handleDoQuery();
          });
        }
      },
      deep: true,
    },
    value(val) {
      if (this.multiple) {
        this.resetInputHeight();
        if (val && val.length > 0) {
          this.currentPlaceholder = "";
        } else {
          this.currentPlaceholder = this.cachedPlaceHolder;
        }
      }
    },
  },
  beforeDestroy() {
    if (this.$el && this.handleResize)
      removeResizeListener(this.$el, this.handleResize);
  },
};
</script>

<style lang="less">
.datagrid-picker-popper {
  z-index: 9999 !important;
}
.datagrid-picker-pagination-popper {
  z-index: 10000 !important;
}
</style>