<template>
  <div class="el-select quick-cascader">
    <el-input :size="size" placeholder="正在加载中…" readonly :disabled="disabled" v-if="loading"></el-input>
    <el-cascader v-else :size="size" :placeholder="placeholder" :options="store" v-model="ownValue" :filterable="filterable" :show-all-levels="showAllLevels" :props="calcProps" :loading="loading" :disabled="disabled" :clearable="clearable" @change="handleChange" @clear="handleClear" :collapse-tags="collapseTags" style="width: 100%;" />
    <!-- <el-select v-else :size="size" :placeholder="placeholder" v-model="ownValue" :value-key="valueField" :multiple="multiple" :filterable="filterable" :allow-create="allowCreate" :default-first-option="defaultFirstOption" :loading="loading" :disabled="disabled" :clearable="clearable" @change="handleChange" @clear="handleClear">
    <el-option v-for="item in store" :key="item[valueField]" :label="item[displayField]" :value="item[valueField]" />
    </el-select>-->
  </div>
</template>

<script>
import Emitter from "element-ui/src/mixins/emitter";
import { valueEquals } from "element-ui/src/utils/util";
import request from "@/utils/request";

export default {
  mixins: [Emitter],
  props: {
    value: String | Number | Array,
    url: String,
    params: Object | String | Number,
    options: Array,
    multiple: Boolean,
    label: String | Number | Array,
    valueField: { type: String, default: "id" },
    displayField: { type: String, default: "name" },
    parentField: { type: String, default: "parentId" },
    childrenField: { type: String, default: "children" },
    isLeafField: { type: String, default: "isLeaf" },
    filterable: Boolean,
    placeholder: String,
    disabled: Boolean,
    clearable: Boolean,
    disabledKeys: Array,
    lazy: Boolean,
    size: {type: String, default: "small"},
    expandTrigger: { type: String, default: "click" },
    showAllLevels: Boolean,
    checkStrictly: Boolean,
    emitPath: Boolean,
    flat: Boolean,
    collapseTags:Boolean,
    rootKey: {
      type: String | Object | Number,
      default: null
    }
  },
  data() {
    return {
      ownValue: null,
      loading: false,
      store: []
    };
  },
  computed: {
    calcProps() {
      return {
        multiple: this.multiple,
        value: this.valueField,
        label: this.displayField,
        children: this.childrenField,
        leaf: this.isLeafField,
        expandTrigger: this.expandTrigger,
        checkStrictly: this.checkStrictly,
        emitPath: this.emitPath,
        lazy: this.lazy,
        lazyLoad: this.lazyLoad
      };
    }
  },
  methods: {
    checkDisabledKeys(source, disabled = false) {
      let ks = this.disabledKeys || [];
      if (source && source.length) {
        source.forEach(o => {
          o.disabled = disabled || ks.indexOf(o[this.valueField]) >= 0;
          this.checkDisabledKeys(o[this.childrenField], o.disabled);
        });
      }
    },
    getFullInfo(val) {
      let ids = [],
        labels = [];
      this.findNode(this.store, val, ids, labels);
      return {
        values: ids,
        labels: labels
      };
    },
    findNode(source, val, ids, labels) {
      let found = false;
      if (source && source.length) {
        for (let s of source) {
          if (s[this.valueField] === val) {
            found = true;
          } else {
            found = this.findNode(s[this.childrenField], val, ids, labels);
          }
          if (found) {
            ids.unshift(s[this.valueField]);
            labels.unshift(s[this.displayField]);
            break;
          }
        }
      }
      return found;
    },
    load(update = true) {
      if (this.url) {
        let o = {};
        if (typeof this.params === "string") {
          o = this.params;
        } else if (this.$isPlainObject(this.params)) {
          o = Object.assign(o, this.params);
        }
        this.loading = true;
        request({
          url: this.url,
          method: "get",
          params: o
        })
          .then(res => {
            let _res = this.$isArray(res) ? res : res.content;
            if (this.flat) {
              _res = this.$buildTree(_res, this.rootKey, {
                idField: this.valueField,
                parentField: this.parentField,
                childrenField: this.childrenField
              });
            }
            this.checkDisabledKeys(_res);
            this.store = _res;
            this.ownValue = this.value;
            this.updateValue(this.ownValue);
          })
          .catch(err => {
            this.store = [];
          })
          .finally(_ => {
            this.loading = false;
          });
      } else {
        this.checkDisabledKeys(this.options);
        this.store = this.options;
        this.ownValue = this.value;
        if (update) this.updateValue(this.ownValue);
      }
    },
    lazyLoad(node, resolve) {
      if (node) {
        let o = Object.assign({}, this.params);
        if (!node.root && node.data) {
          o[this.parentField] = node.data[this.valueField];
        } else {
          o[this.parentField] = this.rootKey;
        }
        request({
          url: this.url,
          method: "get",
          params: o
        })
          .then(res => {
            resolve(this.$isArray(res) ? res : res.content || []);
          })
          .catch(err => {
            resolve([]);
          });
      } else {
        resolve([]);
      }
    },
    handleChange(val) {
      if (!valueEquals(this.value, val)) {
        this.updateValue(val);
        this.dispatch("ElFormItem", "el.form.change", val);
      }
    },
    handleClear() {
      this.updateValue(this.ownValue);
      this.$emit("clear");
    },
    findData(source, val) {
      let res = null;
      if (source && source.length) {
        for (let s of source) {
          if (s[this.valueField] === val) {
            res = s;
          } else {
            res = this.findData(s[this.childrenField], val);
          }
          if (res) {
            break;
          }
        }
      }
      return res;
    },
    updateValue(val) {
      let labels = [],
        objects = [],
        vals = val;
      if (!this.$isArray(val)) {
        vals = [val];
      }
      if (vals && vals.length) {
        for (let v of vals) {
          let o = this.findData(this.store, v);
          objects.push(o);
          if (o) {
            labels.push(o[this.displayField] || "");
          } else {
            labels.push("");
          }
        }
      }
      if (!this.multiple) {
        labels = labels.length ? labels[0] : "";
        objects = objects.length ? objects[0] : null;
      }
      this.$emit("update:label", labels);
      if (valueEquals(this.value, val)) return;

      this.$emit("input", val);
      this.$emit("change", val, objects);
    }
  },
  mounted() {
    if (!this.lazy) {
      this.load();
    }
  },
  watch: {
    url: "load",
    params: {
      handler: function(newParams, oldParams) {
        if (
          !valueEquals(newParams, oldParams) &&
          JSON.stringify(newParams) !== JSON.stringify(oldParams)
        ) {
          this.load(false);
        }
      },
      deep: true
    },
    value: function(val) {
      this.ownValue = val;
    },
    disabledKeys: {
      deep: true,
      handler: function() {
        this.checkDisabledKeys(this.store);
      }
    }
  }
};
</script>