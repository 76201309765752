import merge from "webpack-merge";
import baseConfig from "./config.base";

const config = {
  hosts: {
    resource: "https://adinghuo.com/",
    request: "https://adinghuo.com:8081/",
    analysis:"https://analysis.adinghuo.com/",
  },
};

export default merge(baseConfig, config);
