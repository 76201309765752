<template>
  <div class="h c app-component-dot" :class="[type, {same: same}]">
    <div class="_dot"></div>
    <div class="text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "default"
    },
    same: Boolean
  }
};
</script>

<style lang="less">
.app-component-dot {
  > ._dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;
  }

  > .text {
    margin-left: 5px;
  }

  &.disabled {
    &.same {
      color: #c0c4cc;
    }

    > ._dot {
      background-color: #c0c4cc;
    }
  }

  &.gray, &.info {
    &.same {
      color: #909399;
    }

    > ._dot {
      background-color: #909399;
    }
  }

  &.dark {
    &.same {
      color: #303133;
    }

    > ._dot {
      background-color: #303133;
    }
  }

  &.primary {
    &.same {
      color: #409EFF;
    }

    > ._dot {
      background-color: #409EFF;
    }
  }

  &.success {
    &.same {
      color: #67c23a;
    }

    > ._dot {
      background-color: #67c23a;
    }
  }

  &.warning {
    &.same {
      color:#e6a23c;
    }

    > ._dot {
      background-color:#e6a23c;
    }
  }

  &.danger {
    &.same {
      color: #f56c6c;
    }

    > ._dot {
      background-color: #f56c6c;
    }
  }
}
</style>