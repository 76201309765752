import baseConfig from './config.base'
import devConfig from './config.dev'
import prodConfig from './config.prod'
import merge from 'webpack-merge'

let config = {}
/* global process */
if (process.env.NODE_ENV === 'production') {
  config = prodConfig
} else {
  config = devConfig
}

export default merge(baseConfig, config)
