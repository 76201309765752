<template>
  <div class="app-mall">
    <a ref="topAnchor"></a>
    <div ref="fixed" class="container fixed h c">
      <div class="content h">
        <div class="h c" v-if="user">
          <div class="avatar"></div>
          <div class="padding-0-10">{{user.entName}}</div>
        </div>
        <div class="flex"></div>
        <button class="h c" @click="handleCart">
          <i class="fas fa-shopping-cart"></i>
          <span>购物车</span>
          <span class="badge" v-if="cartTotal">（{{cartTotal > 99 ? '99+' : cartTotal}}）</span>
        </button>
        <button class="h c">
          <i class="fas fa-star"></i>
          <span>我的收藏</span>
        </button>
      </div>
    </div>
    <div class="shop-body">
      <div ref="header" class="container padding-20-0">
        <div class="content h c">
          <div class="logo">采购商城</div>
          <div class="padding-10">
            <quick-select ref="sel_supplier" v-model="supplier" filterable url="api/purchase/seller" value-field="belongTo" display-field="belongToName" auto-select-first-option />
          </div>
          <div class="flex"></div>
          <div class="search-box h">
            <i class="fas fa-search"></i>
            <input v-model.trim="keyword" placeholder="输入商品名称" @keypress.enter="handleQuery" />
            <button @click="handleQuery">搜索</button>
          </div>
        </div>
      </div>
      <div ref="navigate" class="container navigation">
        <div class="content h">
          <div id="category-selector" :class="{showable: $route.path !== homePagePath}">
            <div class="reference">品牌/系列</div>
            <div class="list">
              <category :supplier="supplier" inline />
            </div>
          </div>
          <router-link v-for="n in navigation" :key="n.path" :to="n.path" :class="{selected: $route.path.indexOf(n.path) === 0}">{{n.name}}</router-link>
        </div>
      </div>
      <template v-if="supplier">
        <keep-alive>
          <router-view ref="content" :supplier="supplier" />
        </keep-alive>
      </template>
    </div>
    <!-- <div class="container bc-l">
      <div class="content padding-20"></div>
    </div>-->
    <transition name="fade">
      <div class="topper" v-show="showTopper">
        <i class="fas fa-arrow-up" @click="handleScrollToTop"></i>
      </div>
    </transition>
    <qq-service :supplier="supplier" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import Category from "./assembly/category";
import qqService from "./assembly/qq-service";
import { mapGetters } from "vuex";
import request from "@/utils/request";

export default {
  components: { Category, qqService },
  data() {
    return {
      homePagePath: "/mall/home",
      keyword: "",
      showTopper: false,
      supplier: null,
      navigation: [
        {
          name: "首页",
          path: "/mall/home",
        },
        {
          name: "商品目录",
          path: "/mall/list",
        },
        {
          name: "组合销售",
          path: "/mall/group/list",
        },
        {
          name: "促销活动",
          path: "/mall/marketing",
        },
        {
          name: "购物车",
          path: "/mall/cart",
        },
        {
          name: "订单管理",
          path: "/mall/order",
        },
      ],
      cart: {},
      history: {},
    };
  },
  computed: {
    ...mapGetters(["user"]),
    cartKey() {
      if (this.supplier && this.user)
        return this.supplier + "_" + this.user.entId;
      else return "_default";
    },
    cartTotal() {
      let nums = 0;
      if (this.cartKey && this.cart && this.cart.hasOwnProperty(this.cartKey)) {
        this.cart[this.cartKey].goods.forEach((c) => {
          nums += c.count;
        });
        this.cart[this.cartKey].groups.forEach((c) => {
          (c.items || []).forEach((o) => {
            nums += o.count;
          });
        });
      }
      return nums;
    },
  },
  methods: {
    checkPermission,
    handleScroll(e) {
      if (e.target.scrollTop >= 200) {
        this.showTopper = true;
      } else {
        this.showTopper = false;
      }
      let cel = this.$refs.content;
      if (cel && cel.onScroll) {
        let hs =
          this.$refs.header.offsetHeight + this.$refs.navigate.offsetHeight;
        cel.onScroll(e.target.scrollTop, hs);
      }
    },
    handleScrollToTop(e) {
      this.$refs.topAnchor && this.$refs.topAnchor.scrollIntoView(true);
    },
    handleQuery() {
      if (this.keyword) {
        this.$router.push({
          path: "/mall/list?kw=" + encodeURIComponent(this.keyword),
        });
      } else {
        this.$router.push({
          path: "/mall/list",
        });
      }
    },
    handleCart() {
      this.$router.push({
        path: "/mall/cart",
      });
    },
    handleCartGet() {
      return this.cart[this.cartKey];
    },
    handleCartAdd(type, item) {
      if (item && item._id) {
        if (!this.cart.hasOwnProperty(this.cartKey)) {
          this.cart[this.cartKey] = {
            goods: [],
            groups: [],
          };
        }
        let store = this.cart[this.cartKey];
        if (type === "goods") {
          let exist = store.goods.find((g) => {
            return g.goodsId === item.goodsId;
          });
          if (exist) {
            this.$confirm(
              "当前商品已经存在购物车中，请选择要进行的操作：<br />特殊采购：不进行合并，作为另一商品进行采购；<br />更改数量：在相同商品中累加当前所选数量。",
              "加入购物车",
              {
                dangerouslyUseHTMLString: true,
                type: "warning",
                confirmButtonText: "更改数量",
                cancelButtonText: "特殊采购",
                beforeClose: (action, instance, done) => {
                  if (action === "confirm") {
                    exist.count += item.count;
                  } else if (action === "cancel") {
                    store.goods.push(Object.assign(item, { _checked: false }));
                  }
                  if (action !== "close") {
                    this.handleCartSave();
                    this.$message.success("加入购物车成功");
                  }
                  done();
                },
              }
            );
          } else {
            store.goods.push(Object.assign(item, { _checked: false }));
            this.handleCartSave();
            this.$message.success("加入购物车成功");
          }
        } else if (type === "group") {
          let exist = store.groups.find((g) => {
            return g._id === item._id;
          });
          if (exist) {
            exist.times += item.times;
            exist.items.forEach((o) => {
              o.count = o.groupSaleRule.quantity * exist.times;
            });
          } else {
            store.groups.push(Object.assign(item, { _checked: false }));
            this.handleCartSave();
            this.$message.success("加入购物车成功");
          }
        }
      }
    },
    handleCartUpdateCount(type, item) {
      if (item && item.id) {
        let exist = null;
        if (type === "goods") {
          exist = this.cart[this.cartKey].goods.find((g) => {
            return g._id === item._id;
          });
          if (exist) {
            exist.count = item.count;
            this.handleCartSave();
          }
        } else if (type === "group") {
          let exist = this.cart[this.cartKey].groups.find((g) => {
            return g._id === item._id;
          });
          if (exist) {
            exist.times = item.times;
            exist.items.forEach((g) => {
              g.count = g.groupSaleRule.quantity * exist.times;
            });
            this.handleCartSave();
          }
        }
      }
    },
    handleCartUpdateCheck(type, item) {
      if (typeof type === "boolean") {
        this.cart[this.cartKey].goods.forEach((o) => {
          if (
            !(item === true && o.purchaseLimit) ||
            !!(o.source && o.source.length)
          )
            o._checked = type;
        });
        this.cart[this.cartKey].groups.forEach((o) => {
          o._checked = type;
        });
        this.handleCartSave();
      } else if (type === "goods") {
        let exist = this.cart[this.cartKey].goods.find((o) => {
          return o._id === item._id;
        });
        if (exist) exist._checked = item._checked;
        this.handleCartSave();
      } else if (type === "group") {
        let exist = this.cart[this.cartKey].groups.find((o) => {
          return o._id === item._id;
        });
        if (exist) exist._checked = item._checked;
        this.handleCartSave();
      }
    },
    handleCartRemove(type, item) {
      if (item) {
        let ds = this.cart[this.cartKey][type];
        let inx = ds.findIndex((s) => {
          return s._id === item._id;
        });
        if (inx >= 0) {
          ds.splice(inx, 1);
          this.$message.success("从购物车移除商品成功");
          this.handleCartSave();
        }
      }
    },
    handleCartClear(clearAll = true) {
      if (clearAll) {
        this.cart[this.cartKey].goods = [];
        this.cart[this.cartKey].groups = [];
      } else {
        this.cart[this.cartKey].goods = this.cart[this.cartKey].goods.filter(
          (o) => {
            return !o._checked;
          }
        );
        this.cart[this.cartKey].groups = this.cart[this.cartKey].groups.filter(
          (o) => {
            return !o._checked;
          }
        );
      }
      this.handleCartSave();
    },
    handleCartSave() {
      window.localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    handleHistoryGet() {
      if (this.history.hasOwnProperty(this.user.entId)) {
        let hs = this.history[this.user.entId].filter((h) => {
          return h.supplier === this.supplier;
        });
        return hs;
      } else {
        return [];
      }
    },
    handleHistoryAdd(goods) {
      if (!this.history.hasOwnProperty(this.user.entId)) {
        this.history[this.user.entId] = [];
      }
      let inx = this.history[this.user.entId].findIndex((o) => {
        return o.id === goods.id;
      });
      if (inx >= 0) this.history[this.user.entId].splice(inx, 1);
      this.history[this.user.entId].unshift(goods);
      if (this.history[this.user.entId].length > 100) {
        this.history[this.user.entId].length = 100;
      }
      window.localStorage.setItem("history", JSON.stringify(this.history));
    },
  },
  mounted() {
    if (this.checkPermission(["MARKET_ALL", "MARKET_PURCHASE"])) {
      request({
        url: "api/check/erp",
        method: "get",
      }).then((res) => {
        this.$erp.enable(res === true);
      });

      request({
        url: "api/current/time",
        method: "get",
      }).then((res) => {
        this.$now.set(res || new Date().getTime());
      });
      try {
        this.cart = JSON.parse(window.localStorage.getItem("cart"));
      } catch (e) {}
      if (!this.cart) {
        this.cart = {};
      }

      this.history = JSON.parse(window.localStorage.getItem("history") || "{}");
      this.$el.addEventListener("scroll", this.handleScroll);
      window.mall = {
        cart: {
          get: this.handleCartGet,
          add: this.handleCartAdd,
          updateCount: this.handleCartUpdateCount,
          updateCheck: this.handleCartUpdateCheck,
          remove: this.handleCartRemove,
          clear: this.handleCartClear,
          save: this.handleCartSave,
        },
        history: {
          get: this.handleHistoryGet,
          add: this.handleHistoryAdd,
        },
      };
    } else {
      this.$router.replace("/404");
    }
  },
};
</script>

<style lang="less">
@import "../../styles/shop.less";
</style>