<template>
  <div>
    <el-table ref="table" :data="pagedStore" :border="border" :class="cls" :loading="loading" :highlight-current-row="highlightCurrentRow" :row-class-name="rowClassName" :row-style="rowStyle" :height="height" :max-height="maxHeight" @current-change="handleCurrentChange" @selection-change="handleSelectionChange" @row-click="handleRowClick">
      <el-table-column key="__local_table_index" type="index" :index="generateColumnIndex" v-bind="calcedIndexProps" v-if="showIndex" />
      <slot></slot>
    </el-table>
    <el-pagination :total="store.length" :current-page.sync="page" :page-size.sync="size" :layout="pageLayout" :style="pageStyle" hide-on-single-page />
  </div>
</template>

<script>
export default {
  props: {
    store: {
      type: Array,
      default: () => {
        return [];
      },
    },
    pageSize: {
      type: Boolean,
      default: 20,
    },
    pageLayout: {
      type: String,
      default: "total, prev, pager, next, sizes"
    },
    pageStyle: String | Object,
    height: String,
    maxHeight: String,
    loading: Boolean,
    cls: String,
    border: Boolean,
    highlightCurrentRow: Boolean,
    rowClassName: Function,
    showIndex: Boolean,
    indexProps: Object,
    rowStyle: Object | Function,
  },
  data() {
    return {
      page: 1,
      size: 0,
      defaultIndexProps: { width: 50, label: "序号", align: "right" },
    };
  },
  watch: {
    store() {
      this.page = 1;
    },
  },
  computed: {
    pagedStore() {
      let start = (this.page - 1) * this.size,
        len = this.size;
      if (start + len > this.store.length) {
        len = this.store.length - start;
      }
      return this.store.slice(start, start + len);
    },
    calcedIndexProps() {
      return Object.assign({}, this.defaultIndexProps, this.indexProps);
    },
  },
  methods: {
    clearSelection() {
      this.$refs.table && this.$refs.table.clearSelection();
    },
    doLayout() {
      this.$refs.table && this.$refs.table.doLayout();
    },
    handleCurrentChange() {
      this.$emit("current-change", ...arguments);
    },
    handleSelectionChange() {
      this.$emit("selection-change", ...arguments);
    },
    handleRowClick() {
      this.$emit("row-click", ...arguments);
    },
    generateColumnIndex(ownIndex) {
      return (this.page - 1) * this.size + ownIndex + 1;
    },
  },
  mounted() {
    this.size = this.pageSize;
  }
};
</script>