<template>
  <div :class="[prefixCls, readOnly ? `${prefixCls}-readonly` : '', disabled ? `${prefixCls}-disabled` : '', dark ? 'dark' : '']">
    <div :class="[prefixCls + '-comm']" v-if="!disabled">
      <div class="comm-group">
        <el-button @click.stop="isCodeMode = !isCodeMode" :class="{checked: isCodeMode}" class="comm-item">
          <i class="fa fa-code"></i>
        </el-button>
        <!-- <el-button @click.stop="dark = !dark" :class="{checked: dark}" class="comm-item">
          <i class="fas fa-adjust"></i>
        </el-button>-->
      </div>

      <div class="comm-group" v-show="showItem">
        <el-button @click.stop="execute('undo')" type="text" :disabled="!hasChange || unoperable.undo" class="comm-item">
          <i class="fa fa-undo"></i>
        </el-button>

        <el-button @click.stop="execute('redo')" type="text" :disabled="!hasChange || unoperable.redo" class="comm-item">
          <i class="fa fa-redo"></i>
        </el-button>
      </div>

      <div class="comm-group" v-show="showItem">
        <el-select v-model="range.fontSize" filterable @change="execute('fontSize', false, $event)" placeholder="字号" size="mini" style="width: 70px;">
          <el-option value="1" label="1号" />
          <el-option value="2" label="2号" />
          <el-option value="3" label="3号" />
          <el-option value="4" label="4号" />
          <el-option value="5" label="5号" />
          <el-option value="6" label="6号" />
          <el-option value="7" label="7号" />
        </el-select>

        <el-button @click.stop="execute('justifyLeft')" :class="{checked: range.alignLeft}" class="comm-item">
          <i class="fa fa-align-left"></i>
        </el-button>

        <el-button @click.stop="execute('justifyCenter')" :class="{checked: range.alignCenter}" class="comm-item">
          <i class="fa fa-align-center"></i>
        </el-button>

        <el-button @click.stop="execute('justifyRight')" :class="{checked: range.alignRight}" class="comm-item">
          <i class="fa fa-align-right"></i>
        </el-button>

        <el-button @click.stop="execute('justifyFull')" :class="{checked: range.alignJustify}" class="comm-item">
          <i class="fa fa-align-justify"></i>
        </el-button>
      </div>

      <div class="comm-group" v-show="showItem">
        <el-button @click.stop="execute('bold')" :class="{checked: range.bold}" class="comm-item">
          <i class="fa fa-bold"></i>
        </el-button>

        <el-button @click.stop="execute('italic')" :class="{checked: range.italic}" class="comm-item">
          <i class="fa fa-italic"></i>
        </el-button>

        <el-button @click.stop="execute('underline')" :class="{checked: range.underline}" class="comm-item">
          <i class="fa fa-underline"></i>
        </el-button>

        <el-button @click.stop="execute('strikeThrough')" :class="{checked: range.strikeThrough}" class="comm-item">
          <i class="fa fa-strikethrough"></i>
        </el-button>

        <el-button @click.stop="execute('superscript')" :class="{checked: range.superscript}" class="comm-item">
          <i class="fa fa-superscript"></i>
        </el-button>

        <el-button @click.stop="execute('subscript')" :class="{checked: range.subscript}" class="comm-item">
          <i class="fa fa-subscript"></i>
        </el-button>
      </div>

      <div class="comm-group" v-show="showItem">
        <el-color-picker size="small" v-model="range.foreColor" @change="changeForeColor" :disabled="range.length === 0" />
        <el-color-picker size="small" v-model="range.backColor" @change="changeBackColor" :disabled="range.length === 0" />
      </div>

      <div class="comm-group" v-show="showItem">
        <el-button @click.stop="execute('insertOrderedList')" :class="{checked: range.ol}" class="comm-item">
          <i class="fa fa-list-ol"></i>
        </el-button>

        <el-button @click.stop="execute('insertUnorderedList')" :class="{checked: range.ul}" class="comm-item">
          <i class="fa fa-list-ul"></i>
        </el-button>

        <el-button @click.stop="execute('outdent')" type="text" class="comm-item">
          <i class="fa fa-outdent"></i>
        </el-button>

        <el-button @click.stop="execute('indent')" type="text" class="comm-item">
          <i class="fa fa-indent"></i>
        </el-button>
      </div>

      <div class="comm-group" v-show="showItem">
        <el-button @click.stop="handleSpellCheckChange" :class="{checked: spellcheckable}" class="comm-item">
          <i class="fa fa-pen-nib"></i>
        </el-button>

        <el-button @click.stop="execute('removeFormat')" type="text" class="comm-item" :disabled="range.length === 0" icon="erase">
          <i class="fa fa-eraser"></i>
        </el-button>
      </div>

      <div class="comm-group" v-show="showItem">
        <el-button type="text" class="comm-item" @click.stop="handleInsertImage">
          <i class="fa fa-image"></i>
        </el-button>
        <el-button type="text" class="comm-item" @click.stop="handleInsertLink">
          <i class="fa fa-link"></i>
        </el-button>
      </div>
    </div>
    <div :class="[prefixCls + '-content']" :style="{height: height + 'px'}">
      <file-uploader ref="pasteUpload" :visible="false" entity-id="temp" folder="custom-paste" />
      <iframe ref="html-editor-iframe" frameborder="0" allowtransparency="true" style="width: 100%; height: 100%;"></iframe>
    </div>

    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" append-to-body :title="image.add ? '插入图片' : '编辑图片'" :visible.sync="image.show" width="640px">
      <el-form ref="imageForm" :model="image.model" :rules="image.rules" v-if="image.model" label-position="right" hide-required-asterisk label-width="72px">
        <div class="h s">
          <div style="margin-right: 2rem;" class="flex">
            <el-radio-group v-model="image.model.mode">
              <el-radio-button label="upload">本地上传</el-radio-button>
              <el-radio-button label="link">外链地址</el-radio-button>
            </el-radio-group>
            <el-form-item prop="url" v-if="image.model.mode === 'upload'" label="上传图片" class="gap-1x">
              <file-uploader v-model="image.model.url" :entity-id="image.model.id" folder="custom-upload" />
            </el-form-item>
            <el-form-item prop="url" v-else label="图片地址" class="gap-1x">
              <el-input v-model.trim="image.model.url" :maxlength="300" />
            </el-form-item>
            <el-form-item prop="width" label="图片宽度">
              <el-input v-model.trim="image.model.width" placeholder="默认为100%" />
            </el-form-item>
            <el-form-item prop="height" label="图片高度">
              <el-input v-model.trim="image.model.height" placeholder="默认为自适应" />
            </el-form-item>
          </div>
          <div style="width: 200px;">
            <div class="fc-g">图片预览</div>
            <img :src="image.model.url || '/static/images/no-image.jpg'" style="width: 200px;" />
          </div>
        </div>
      </el-form>

      <div slot="footer">
        <el-button @click.stop="handleCancelInsertImage">取 消</el-button>
        <el-button type="primary" @click.stop="handleExecuteInsertImage">{{image.add ? '插 入' : '确 定'}}</el-button>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" append-to-body :title="link.add ? '插入超链接' : '编辑超链接'" :visible.sync="link.show" width="480px">
      <el-form ref="linkForm" :model="link.model" :rules="link.rules" v-if="link.model" label-position="right" hide-required-asterisk label-width="72px">
        <el-form-item prop="text" label="链接文本">
          <el-input v-model="link.model.text" :maxlength="100" />
        </el-form-item>
        <el-form-item prop="url" label="链接地址">
          <el-input v-model.trim="link.model.url" :maxlength="500" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click.stop="handleCancelInsertLink">取 消</el-button>
        <el-button type="primary" @click.stop="handleExecuteInsertLink">{{image.add ? '插 入' : '确 定'}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Emitter from "element-ui/src/mixins/emitter";

const prefixCls = "el-html-editor";

export default {
  name: "HtmlEditor",
  mixins: [Emitter],
  data() {
    return {
      frame: null,
      document: null,
      body: null,
      isCodeMode: false,
      showItem: true,
      hasChange: false,
      count: 0,
      defaultFontSize: 10.5,
      spellcheckable: this.spellcheck,
      unoperable: {
        undo: true,
        redo: true,
      },
      dark: false,
      range: {
        eanble: false,
        length: 0,
        foreColor: this.foreColor,
        backColor: this.backColor,
        fontSize: 1,
        bold: false,
        italic: false,
        underline: false,
        strikeThrough: false,
        superscript: false,
        subscript: false,
        ol: false,
        ul: false,
        alignLeft: false,
        alignCenter: false,
        alignRight: false,
        alignJustify: false,
      },
      image: {
        add: false,
        model: null,
        rules: {
          url: [{ required: true, message: "请上传图片或填写图片地址" }],
        },
        show: false,
      },
      link: {
        add: false,
        model: null,
        rules: {
          url: [{ required: true, message: "请填写超链接地址" }],
        },
        show: false,
      },
      prefixCls: prefixCls,
    };
  },
  props: {
    value: {
      type: String,
    },
    height: {
      type: Number,
      default: 200,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    codeMode: {
      type: Boolean,
      default: false,
    },
    foreColor: {
      type: String,
      default: "#495060",
      validator(value) {
        return value == null || value === "" || /^#[0-9a-f]{6}$/i.test(value);
      },
    },
    backColor: {
      type: String,
      default: "",
      validator(value) {
        return value == null || value === "" || /^#[0-9a-f]{6}$/i.test(value);
      },
    },
    spellcheck: {
      type: Boolean,
      default: null,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init() {
      this.isCodeMode = this.codeMode;
      this.frame = this.$refs["html-editor-iframe"];
      if (this.frame) {
        this.initStyles();
        this.document = this.frame.contentWindow.document;
        if (this.document) {
          this.body = this.frame.contentWindow.document.body;
          if (this.body) {
            this.updateReadOnly(this.readOnly);
            this.updateDisabled(this.disabled);
            this.updateSpellcheck(this.spellcheckable);
            this.updateMode(this.isCodeMode);
            this.updateUnoperable();
            this.initEventPasteListener();
          }
        }
      }
    },
    initStyles() {
      let head = this.frame.contentWindow.document.head;
      let css = `
        html {
          font-size: 16px;
        }
        body {
          padding: 10px;
          margin: 0;
          font-size: 14px;
          font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","\\5FAE\\8F6F\\96C5\\9ED1",Arial,sans-serif;
          line-height: 1.5;
          color: #495060;
        }
        body.disabled {
          color: #ccc;
        }
        body.disabled > * {
          opacity: .6;
        }
        a {
          color: #2d8cf0;
          text-decoration: none;
        }
        a:hover {
          color: #5cadff;
        }
        div[data-type='COUPON'] {
          color: #2d8cf0;
        }
        div[data-type='COUPON']::before {
          content: '[优惠劵] '
        }
        `;

      let style = document.createElement("style");
      style.type = "text/css";
      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }

      head.appendChild(style);
    },
    dropCheck(e) {
      let able = true;
      if (e.dataTransfer.types) {
        for (let i = 0; i < e.dataTransfer.types.length; i++) {
          if (e.dataTransfer.types[i] === "Files") {
            able = false;
            break;
          }
        }
      }

      if (able) {
        this.updateValue();
      } else {
        return false;
      }
    },
    handleInsertImage() {
      this.image.add = true;
      this.image.show = true;
      this.image.model = {
        id: this.$uuid(),
        url: null,
        width: "",
        height: "",
        mode: "upload",
      };
    },
    handleExecuteInsertImage() {
      if (this.image.model) {
        this.$refs.imageForm &&
          this.$refs.imageForm
            .validate()
            .then((_) => {
              let html = `<img src="${this.image.model.url}" data-id="${
                this.image.model.id
              }" data-mode="${this.image.model.mode}" style="${
                this.image.model.width
                  ? "width: " + this.image.model.width + ";"
                  : ""
              }${
                this.image.model.height
                  ? "height: " + this.image.model.height + ";"
                  : ""
              }" />`;

              this.execute("insertHtml", true, html);
              this.image.model = null;
              this.image.show = false;
            })
            .catch((_) => {});
      } else {
        this.image.show = false;
      }
    },
    handleCancelInsertImage() {
      this.image.model = null;
      this.image.show = false;
    },
    handleInsertLink() {
      this.link.add = true;
      this.link.show = true;
      this.link.model = {
        text: "",
        url: "",
      };
    },
    handleExecuteInsertLink() {
      if (this.link.model) {
        this.$refs.linkForm &&
          this.$refs.linkForm
            .validate()
            .then((_) => {
              let html = `<a href="${this.link.model.url}">${
                this.link.model.text || this.link.model.url
              }</a>`;

              this.execute("insertHtml", true, html);
              this.link.model = null;
              this.link.show = false;
            })
            .catch((_) => {});
      } else {
        this.link.show = false;
      }
    },
    handleCancelInsertLink() {
      this.link.model = null;
      this.link.show = false;
    },
    clean(code) {
      // 防注入
      if (typeof code === "string") {
        return code
          .replace(/<\s*\/?\s*(script|head|meta|html)[^<>]*>/gi, "")
          .replace(/\<\/?o:p([^\>]+)?\>/gi, "");
      } else {
        return code || "";
      }
    },
    clickCheck(e) {
      let selection = this.frame.contentWindow.window.getSelection();
      let range = this.document.createRange();
      selection.removeAllRanges();
      range.selectNode(e.target);
      selection.addRange(range);

      switch (e.target.tagName.toLowerCase()) {
        case "img":
          this.image.add = false;
          this.image.model = {
            id: e.target.dataset.id,
            mode: e.target.dataset.mode || "link",
            url: e.target.src,
            width: e.target.style.width,
            height: e.target.style.height,
          };
          this.image.show = true;
          break;
        case "a":
          this.link.add = false;
          this.link.model = {
            url: e.target.href,
            text: e.target.innerText,
          };
          this.link.show = true;
          break;
      }
    },
    addListeners() {
      this.body.addEventListener("keyup", this.updateValue);
      this.body.addEventListener("dblclick", this.clickCheck);
      this.body.addEventListener("drop", this.dropCheck);
      this.body.addEventListener("paste", this.handlePaste);
      this.document.addEventListener("selectionchange", this.updateSelection);
    },
    removeListeners() {
      this.body.removeEventListener("keyup", this.updateValue);
      this.body.removeEventListener("dblclick", this.clickCheck);
      this.body.removeEventListener("drop", this.dropCheck);
      this.body.removeEventListener("paste", this.handlePaste);
      this.document.removeEventListener(
        "selectionchange",
        this.updateSelection
      );
    },
    updateReadOnly(readonly) {
      if (this.body) {
        this.removeListeners();
        this.body.setAttribute("contenteditable", !readonly && !this.disabled);
        this.showItem = !readonly && !this.isCodeMode;
        if (!readonly) {
          this.addListeners();
        }
      }
    },
    updateDisabled(disabled) {
      if (this.body) {
        this.removeListeners();
        this.body.setAttribute("contenteditable", !disabled && !this.readOnly);
        if (disabled) {
          this.body.classList.add("disabled");
        } else {
          this.body.classList.remove("disabled");
          this.addListeners();
        }
      }
    },
    updateMode(code) {
      if (typeof code !== "boolean") {
        code = this.isCodeMode;
      }
      if (this.body) {
        let v = this.clean(this.value);
        if (code) {
          this.body.innerText = v;
        } else {
          this.body.innerHTML = v;
          this.count = this.body.innerText.length;
        }

        this.hasChange = false;
        this.showItem = !code && !this.readOnly;

        if (this.autoFocus) {
          this.body.focus();
        }
      }
    },
    updateSpellcheck(v) {
      if (this.body) {
        this.body.setAttribute("spellcheck", v === true);
      }
    },
    updateValue() {
      if (this.body) {
        this.hasChange = true;
        let value = this.clean(
          this.isCodeMode ? this.body.innerText : this.body.innerHTML
        );

        this.$emit("input", value);
        this.$emit("change", value);
        this.visible = false;
        this.dispatch("ElFormItem", "el.form.change", value);

        this.updateUnoperable();
      }
    },
    updateSelection() {
      if (this.document) {
        let sel = this.frame.contentWindow.window.getSelection();
        if (sel.rangeCount) {
          this.range.enable = true;

          let rng = sel.getRangeAt(0);
          this.range.length = Math.abs(rng.endOffset - rng.startOffset);
          this.range.bold = this.document.queryCommandState("bold");
          this.range.italic = this.document.queryCommandState("italic");
          this.range.underline = this.document.queryCommandState("underline");
          this.range.strikeThrough = this.document.queryCommandState(
            "strikeThrough"
          );
          this.range.superscript = this.document.queryCommandState(
            "superscript"
          );

          this.range.alignLeft = this.document.queryCommandState("justifyLeft");
          this.range.alignCenter = this.document.queryCommandState(
            "justifyCenter"
          );
          this.range.alignRight = this.document.queryCommandState(
            "justifyRight"
          );
          this.range.alignJustify = this.document.queryCommandState(
            "justifyFull"
          );

          this.range.ol = this.document.queryCommandState("insertOrderedList");
          this.range.ul = this.document.queryCommandState(
            "insertUnorderedList"
          );

          this.range.subscript = this.document.queryCommandState("subscript");
          this.range.foreColor =
            this.document.queryCommandValue("foreColor") || this.foreColor;
          this.range.backColor =
            this.document.queryCommandValue("backColor") || this.backColor;
          this.range.fontSize = String(
            this.document.queryCommandValue("fontSize") || 2
          );

          // let el = rng.commonAncestorContainer;
          // if (el.nodeType === 3) {
          //   el = el.parentNode;
          // }
          // if (el) {
          //   let computedStyle = this.frame.contentWindow.window.getComputedStyle(
          //     el,
          //     null
          //   );
          // }

          this.updateUnoperable();
        } else {
          this.range.length = 0;
          this.range.enable = false;
        }

        this.body.focus();
      }
    },
    updateUnoperable() {
      if (this.document) {
        this.unoperable.undo = !this.document.queryCommandEnabled("undo");
        this.unoperable.redo = !this.document.queryCommandEnabled("redo");
        this.unoperable.fontSize = !this.document.queryCommandEnabled(
          "fontSize"
        );
      }
    },
    handleSpellCheckChange() {
      this.spellcheckable = !this.spellcheckable;
      this.isCodeMode = !this.isCodeMode;
      this.$nextTick((_) => {
        this.isCodeMode = !this.isCodeMode;
        this.body.focus();
      });
    },
    changeForeColor(color) {
      if (color) {
        this.execute("foreColor", false, color);
      } else {
        this.execute("removeFormat", false, "foreColor");
      }
    },
    changeBackColor(color) {
      if (color) {
        this.execute("backColor", false, color);
      } else {
        this.execute("removeFormat", false, "backColor");
      }
    },
    execute() {
      this.document.execCommand(...arguments);
      this.$nextTick(() => {
        this.updateValue();
        this.updateSelection();
      });
    },
    initEventPasteListener() {
      if (this.body) {
        this.body.addEventListener("paste", (event) => {
          if (this.isCodeMode) return;

          if (event.clipboardData.files && event.clipboardData.files.length) {
            event.preventDefault();
            let uploader = this.$refs.pasteUpload;
            if (!uploader) return;
            for (let i = 0, l = event.clipboardData.files.length; i < l; i++) {
              let f = event.clipboardData.files[i],
                ext = uploader.getFileExtension(f.name);
              let file = new File([f], "temp." + ext, { type: f.type });
              if (/^image/.test(file.type)) {
                uploader.doSilenceUpload(file, "custom-pasted").then((url) => {
                  this.execute("insertHtml", true, `<img src='${url}' data-mode='link' data-id='${this.$uuid()}' />`);
                });
              }
            }
          } else {
            let d = event.clipboardData.getData("text/plain");
            let div = document.createElement("div");
            div.innerHTML = d;
            if (div.children[0] && div.children[0].nodeName === "A") {
              event.preventDefault();
              this.execute("insertHtml", true, div.innerHTML);
            }
          }
        });
      }
    },
    handlePaste() {
      setTimeout((_) => {
        this.updateValue();
      }, 100);
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    readOnly: "updateReadOnly",
    disabled: "updateDisabled",
    isCodeMode: "updateMode",
    spellcheckable: "updateSpellcheck",
  },
};
</script>

<style lang="less">
.el-html-editor {
  border: #dcdfe6 solid 1px;
  border-radius: 4px;

  &-comm {
    background-color: #f8f8f9;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    button.comm-item {
      border: none;
      width: 24px;
      height: 24px;
      line-height: 24px !important;
      padding: 0 !important;
      background-color: transparent;
      font-size: 12px;
      margin: 2px;
      border-radius: 2px;
      color: #606266;
      transition: all 275ms;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
        color: #303133;
      }
      &:disabled {
        color: #ccc;
        background-color: transparent;
      }
      &.checked {
        background-color: #409eff;
        color: white;
      }
    }

    .el-select,
    .el-color-picker {
      height: 24px;
      line-height: 24px;
      margin: 2px;
      input {
        height: 24px;
        line-height: 24px;
      }
      .el-input__suffix {
        line-height: 24px;
      }

      .el-color-picker__mask,
      .el-color-picker__trigger {
        width: 24px;
        height: 24px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #dcdfe6;
    }

    div.comm-group {
      border: #dcdfe6 solid 1px;
      border-width: 1px 1px 1px 0;
      margin-top: -1px;
      padding: 0 1px;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &.dark {
    border-color: #131313;

    .el-html-editor-comm {
      background-color: #303133;
      button.comm-item {
        color: rgba(255, 255, 255, 0.8);
        &.hover {
          color: white;
        }
      }

      &:after {
        background-color: #131313;
      }
      div.comm-group {
        border-color: #131313;
      }
    }

    .el-html-editor-content {
      background-color: #505050;
    }
  }

  &-disabled {
    .el-html-editor-content {
      background-color: #f3f3f3;
    }
  }

  &-content {
    position: relative;

    .count {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 5px;
      background-color: #80848f;
      border-radius: 4px 0 0 0;
      color: white;
      opacity: 0.6;
      transition: opacity 275ms;
      font-size: 12px;

      &:hover {
        opacity: 1;
      }
    }
  }

  .ivu-color-picker-btn .ivu-color-picker-preview {
    bottom: 2px !important;
    left: 6px !important;
    width: 15px !important;
  }
}
</style>
