<template>
  <div class="app-common-percent-input" :class="[symbol ? 'with-symbol' : '', size ? `app-common-percent-input--${size}` : '']">
    <el-input-number v-model="inputValue" :min="min" :max="max" :step="1" :precision="precision" :size="size" :disabled="disabled" controls controls-position="right" :placeholder="placeholder" @change="handleChange" style="width: 100%;" />
    <div class="symbol" v-if="symbol">{{symbol}}</div>
  </div>
</template>

<script>
import Emitter from "element-ui/src/mixins/emitter";
import { valueEquals } from "element-ui/src/utils/util";

export default {
  mixins: [Emitter],
  props: {
    symbol: {
      type: String,
      default: "%"
    },
    value: Number,
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    precision: {
      type: Number,
      default: 2
    },
    base : {
      type: Number,
      default: 1
    },
    size: String,
    disabled: Boolean,
    placeholder: String
  },
  data() {
    return {
      inputValue: this.calcInputValue(this.value)
    };
  },
  methods: {
    calcInputValue(val) {
      val = val || 0;
      if (this.precision > 0) {
        val = val * Math.pow(10, this.precision) / this.base;
      }
      return val;
    },
    handleChange(val) {
      let ov = val || 0;
      if (this.precision > 0) {
        ov = (val || 0) / Math.pow(10, this.precision) * this.base;
      } else {
        ov = Math.round((val || 0) * this.base);
      }

      if (!valueEquals(this.value, ov)) {
        this.$emit("input", ov);
        this.$emit("change", ov);
        this.dispatch("ElFormItem", "el.form.change", ov);
      }
    },
    handleKeypress(e) {
      this.$emit("keypress", e);
    }
  },
  watch: {
    value: function(newVal, oldVal) {
      this.inputValue = this.calcInputValue(newVal);
    }
  }
};
</script>

<style lang="less">
.app-common-percent-input {
  position: relative;
  // line-height: 32px;
  .el-input-number .el-input__inner {
    text-align: right;
  }

  .is-disabled + .symbol {
    color: #c0c4cc;
  }

  &.with-symbol .el-input-number .el-input__inner {
    padding-right: 54px !important;
  }

  &.with-symbol > .symbol {
    position: absolute;
    right: 40px;
    top: 50%;
    margin-top: -6px;
    line-height: 12px;
    pointer-events: none;
    // line-height: 32px;
  }
}
</style>