<template>
  <div class="app-common-price-input" :class="symbol ? 'with-symbol' : ''">
    <el-input-number ref="numer" v-model="inputValue" :min="min" :max="max" :step="step" :precision="precision" :size="size" :disabled="disabled" :controls="controls" controls-position="right" :placeholder="placeholder" @change="handleChange" @blur="handleBlur" style="width: 100%;" />
    <div class="symbol" v-if="symbol">{{symbol}}</div>
  </div>
</template>

<script>
import Emitter from "element-ui/src/mixins/emitter";
import { valueEquals } from "element-ui/src/utils/util";

export default {
  mixins: [Emitter],
  props: {
    symbol: {
      type: String,
      default: "￥"
    },
    value: Number,
    min: {
      type: Number,
      default: -Infinity
    },
    max: {
      type: Number,
      default: 99999999
    },
    autoFixed: {
      type: Boolean,
      default: true
    },
    precision: {
      type: Number,
      default: 2
    },
    controls: {
      type: Boolean,
      default: true
    },
    size: String,
    disabled: Boolean,
    placeholder: String,
    stepRatio: {
      type: Number,
      default: 100
    }
  },
  computed: {
    step() {
      return (
        (this.precision > 0 ? 1 / Math.pow(10, this.precision) : 1) *
        (this.stepRatio || 1)
      );
    }
  },
  data() {
    return {
      inputValue: null
    };
  },
  mounted() {
    setTimeout(_ => {
      this.inputValue = this.calcInputValue(this.value);
      this.handleChange(this.inputValue);
    }, 100);
  },
  methods: {
    calcInputValue(val) {
      if (this.autoFixed) {
        val = val || 0;
      }
      if (typeof val === "number" && !isNaN(val) && this.precision > 0) {
        val = val / Math.pow(10, this.precision);
      }
      return val;
    },
    handleChange(val) {
      if (this.autoFixed) {
        let ov = val || 0,
          nv = ov;
        if (this.precision > 0) {
          ov = Math.round(ov * Math.pow(10, this.precision));
        }

        if (!valueEquals(this.value, ov)) {
          this.$emit("input", ov);
          this.$emit("change", ov);
          this.dispatch("ElFormItem", "el.form.change", ov);
        }
        if (this.$refs.numer) {
          this.$refs.numer.userInput = "";
          this.$nextTick(_ => {
            this.$refs.numer.userInput = nv.toFixed(this.precision);
          });
        }
      } else {
        let ov = val,
          nv = val;
        if (nv === null || nv === "" || nv === undefined || isNaN(nv)) {
          ov = null;
          nv = null;
        } else if (this.precision > 0) {
          nv = Math.round(nv * Math.pow(10, this.precision));
        }
        if (!valueEquals(this.value, nv)) {
          this.$emit("input", nv);
          this.$emit("change", nv);
          this.dispatch("ElFormItem", "el.form.change", nv);
        }
        this.$nextTick(_ => {
          if (this.$refs.numer) {
            this.$refs.numer.userInput = ov;
            this.$refs.numer.currentValue = ov === null ? "" : ov;
          }
        });
      }
    },
    handleBlur(e) {
      if (this.autoFixed) this.handleChange(this.inputValue);
    }
  },
  watch: {
    value: function(newVal, oldVal) {
      this.inputValue = this.calcInputValue(newVal);
    },
    min: function(newVal, oldVal) {
      if (this.inputValue < newVal) {
        this.inputValue = newVal;
        this.handleChange(newVal);
      }
    },
    max: function(newVal, oldVal) {
      if (this.inputValue > newVal) {
        this.inputValue = newVal;
        this.handleChange(newVal);
      }
    }
  }
};
</script>

<style lang="less">
.app-common-price-input {
  position: relative;
  overflow: hidden;
  // line-height: 32px;
  .el-input-number .el-input__inner {
    text-align: right;
  }

  .is-disabled + .symbol {
    color: #c0c4cc;
  }

  &.with-symbol .el-input-number .el-input__inner {
    padding-left: 30px;
  }

  &.with-symbol > .symbol {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -6px;
    line-height: 12px;
    pointer-events: none;
  }
}
</style>