<template>
  <div style="display: inline-block">
    <el-dialog :visible.sync="dialog" :close-on-click-modal="false" :close-on-press-escape="false" :title="title" append-to-body :show-close="!must" width="500px" @close="cancel">
      <el-alert title="您好，欢迎使用本系统！" type="info" description="为了保障您的账号安全，首次使用系统前请先修改账号的登录密码：" :closable="false" show-icon v-if="must" />

      <el-form ref="form" :model="form" :rules="rules" size="small" label-width="88px" style="margin-top: 20px;">
        <el-form-item label="旧密码" prop="oldPass">
          <el-input v-model="form.oldPass" type="password" auto-complete="on" style="width: 240px;" />
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <div class="h c">
            <el-input v-model="form.newPass" type="password" auto-complete="on" style="width: 240px;" />
            <div class="flex h c strengths" :style="{backgroundColor: strengthColors[strength]}">
              <div class="flex ta-c fs-mini strength" :class="{check: strength === 1}">弱</div>
              <div class="flex ta-c fs-mini strength" :class="{check: strength === 2}">中</div>
              <div class="flex ta-c fs-mini strength" :class="{check: strength >= 3}">强</div>
            </div>
            <el-tooltip trigger="hover" placement="top">
              <div slot="content">
                <b>密码强度</b>
                <div style="margin-top: 6px;">
                  密码必须包含：字数、大写字母、小写字母和特殊字符中的任意三种；
                  <br />建议不要包含连续重复的字符，比如：111, aaa等；
                  <br />建议不要包含容易被猜中的内容：如姓名拼音、生日日期等。
                </div>
              </div>
              <i class="el-icon-question fc-g fs-large"></i>
            </el-tooltip>
          </div>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPass">
          <el-input v-model="form.confirmPass" type="password" :maxlength="20" auto-complete="on" style="width: 240px;" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="cancel" v-if="!must">取消</el-button>
        <el-button :loading="loading" type="primary" :maxlength="20" @click="doSubmit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import { updatePass } from "@/api/user";
export default {
  props: {
    must: Boolean
  },
  computed: {
    strength() {
      let lv = 0,
        s = this.form.newPass;
      if (s.length >= 6) {
        debugger
        if (/\d/.test(s)) lv++;
        if (/[a-z]/.test(s)) lv++;
        if (/[A-Z]/.test(s)) lv++;
        if (/\W/.test(s)) lv++;

        s = s.toLowerCase();
        if (/(.)\1{2,}/.test(s)) lv--;

        if (lv < 1) lv = 1;
      }
      return lv;
    }
  },
  data() {
    const confirmPass = (rule, value, callback) => {
      if (value) {
        if (this.form.newPass !== value) {
          callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请再次输入密码"));
      }
    };
    const checkPass = (rule, value, callback) => {
      if (this.strength < 2) {
        callback(new Error("当前密码强度较差"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      dialog: false,
      title: "修改密码",
      form: { oldPass: "", newPass: "", confirmPass: "" },
      rules: {
        oldPass: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPass: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur"
          },
          { validator: checkPass, trigger: "blur" }
        ],
        confirmPass: [
          { required: true, validator: confirmPass, trigger: "blur" }
        ]
      },
      strengthColors: ["#ececec", "#F56C6C", "#E6A23C", "#67C23A"]
    };
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    doSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          updatePass(this.form)
            .then(res => {
              this.resetForm();
              this.$notify({
                title: "密码修改成功，请重新登录",
                type: "success",
                duration: 1500
              });
              setTimeout(() => {
                store.dispatch("LogOut").then(() => {
                  location.reload(); // 为了重新实例化vue-router对象 避免bug
                });
              }, 1500);
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.dialog = false;
      this.$refs["form"].resetFields();
      this.form = { oldPass: "", newPass: "", confirmPass: "" };
    }
  }
};
</script>

<style scoped>
.strengths {
  margin: 0 10px;
  border-radius: 4px;
}
.strength {
  height: 30px;
  line-height: 30px;
  color: transparent;
  border-right: rgba(255, 255, 255, 0.2) solid 1px;
  user-select: none;
}

.strength:last-child {
  border-right: none;
}

.strength.check {
  color: white;
}
</style>